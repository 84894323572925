import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "ActivityLog",
      "AdminActivityLog",
      "AppEmailTemplate",
      "AppMoreSection",
      "AppMoreSectionType",
      "AppNewsComment",
      "AppNewsCommentReaction",
      "AppNewsPost",
      "AppNewsReaction",
      "AppNotification",
      "AppNotificationType",
      "AppPrivacyPolicy",
      "AppResource",
      "AppSalesCompetitionType",
      "AppTermCondition",
      "BlogPost",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentItemsItems",
      "ComponentLogsLogs",
      "ComponentUiCard",
      "ComponentUiFiles",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiItems",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiSizeAndImage",
      "ComponentUiTab",
      "ComponentUiText",
      "Country",
      "CoverImage",
      "Distributor",
      "Dsc",
      "Event",
      "Guest",
      "Home",
      "HowItWorkSection",
      "I18NLocale",
      "Layout",
      "Leaderboard",
      "Leaderboard2023Salon",
      "Leaderboard2023Stylist",
      "LeaderboardResult",
      "Notification",
      "PasswordlessToken",
      "Product",
      "ProductBenefit",
      "ProductType",
      "ReactIconsIconlibrary",
      "Region",
      "SalesManager",
      "Salon",
      "Stylist",
      "Submission",
      "TermCond",
      "UploadFile",
      "UploadFolder",
      "UserView",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "VideoLibrary"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "ActivityLog",
      "AdminActivityLog",
      "AppEmailTemplate",
      "AppMoreSection",
      "AppMoreSectionType",
      "AppNewsComment",
      "AppNewsCommentReaction",
      "AppNewsPost",
      "AppNewsReaction",
      "AppNotification",
      "AppNotificationType",
      "AppPrivacyPolicy",
      "AppResource",
      "AppSalesCompetitionType",
      "AppTermCondition",
      "BlogPost",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentItemsItems",
      "ComponentLogsLogs",
      "ComponentUiCard",
      "ComponentUiFiles",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiItems",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiSizeAndImage",
      "ComponentUiTab",
      "ComponentUiText",
      "Country",
      "CoverImage",
      "Distributor",
      "Dsc",
      "Event",
      "Guest",
      "Home",
      "HowItWorkSection",
      "I18NLocale",
      "Layout",
      "Leaderboard",
      "Leaderboard2023Salon",
      "Leaderboard2023Stylist",
      "LeaderboardResult",
      "Notification",
      "PasswordlessToken",
      "Product",
      "ProductBenefit",
      "ProductType",
      "ReactIconsIconlibrary",
      "Region",
      "SalesManager",
      "Salon",
      "Stylist",
      "Submission",
      "TermCond",
      "UploadFile",
      "UploadFolder",
      "UserView",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "VideoLibrary"
    ]
  }
};
      export default result;
    

export const BlockingUserDocument = gql`
    mutation blockingUser($input: BlockingUserInput!) {
  blockingUser(input: $input) {
    blocked
    success
  }
}
    `;
export type BlockingUserMutationFn = Apollo.MutationFunction<BlockingUserMutation, BlockingUserMutationVariables>;
export function useBlockingUserMutation(baseOptions?: Apollo.MutationHookOptions<BlockingUserMutation, BlockingUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockingUserMutation, BlockingUserMutationVariables>(BlockingUserDocument, options);
      }
export type BlockingUserMutationHookResult = ReturnType<typeof useBlockingUserMutation>;
export type BlockingUserMutationResult = Apollo.MutationResult<BlockingUserMutation>;
export const CreateAdminDocument = gql`
    mutation createAdmin($data: UsersPermissionsUserInput!) {
  createUsersPermissionsUser(data: $data) {
    data {
      id
    }
  }
}
    `;
export type CreateAdminMutationFn = Apollo.MutationFunction<CreateAdminMutation, CreateAdminMutationVariables>;
export function useCreateAdminMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdminMutation, CreateAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdminMutation, CreateAdminMutationVariables>(CreateAdminDocument, options);
      }
export type CreateAdminMutationHookResult = ReturnType<typeof useCreateAdminMutation>;
export type CreateAdminMutationResult = Apollo.MutationResult<CreateAdminMutation>;
export const CreateDistributorEntityDocument = gql`
    mutation createDistributorEntity($input: DistributorInput!) {
  createDistributor(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateDistributorEntityMutationFn = Apollo.MutationFunction<CreateDistributorEntityMutation, CreateDistributorEntityMutationVariables>;
export function useCreateDistributorEntityMutation(baseOptions?: Apollo.MutationHookOptions<CreateDistributorEntityMutation, CreateDistributorEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDistributorEntityMutation, CreateDistributorEntityMutationVariables>(CreateDistributorEntityDocument, options);
      }
export type CreateDistributorEntityMutationHookResult = ReturnType<typeof useCreateDistributorEntityMutation>;
export type CreateDistributorEntityMutationResult = Apollo.MutationResult<CreateDistributorEntityMutation>;
export const CreateEventDocument = gql`
    mutation createEvent($data: EventInput!) {
  createEvent(data: $data) {
    data {
      id
      attributes {
        media {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export const CreateLinkDocument = gql`
    mutation createLink($data: AppNewsPostInput!) {
  createAppNewsPost(data: $data) {
    data {
      id
      attributes {
        image {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export type CreateLinkMutationFn = Apollo.MutationFunction<CreateLinkMutation, CreateLinkMutationVariables>;
export function useCreateLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateLinkMutation, CreateLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLinkMutation, CreateLinkMutationVariables>(CreateLinkDocument, options);
      }
export type CreateLinkMutationHookResult = ReturnType<typeof useCreateLinkMutation>;
export type CreateLinkMutationResult = Apollo.MutationResult<CreateLinkMutation>;
export const CreateNotificationDocument = gql`
    mutation createNotification($data: AppNotificationInput!) {
  createAppNotification(data: $data) {
    data {
      id
    }
  }
}
    `;
export type CreateNotificationMutationFn = Apollo.MutationFunction<CreateNotificationMutation, CreateNotificationMutationVariables>;
export function useCreateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<CreateNotificationMutation, CreateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNotificationMutation, CreateNotificationMutationVariables>(CreateNotificationDocument, options);
      }
export type CreateNotificationMutationHookResult = ReturnType<typeof useCreateNotificationMutation>;
export type CreateNotificationMutationResult = Apollo.MutationResult<CreateNotificationMutation>;
export const CreateOrUpdateSalonDocument = gql`
    mutation createOrUpdateSalon($input: NewSalonInput!) {
  newSalon(input: $input)
}
    `;
export type CreateOrUpdateSalonMutationFn = Apollo.MutationFunction<CreateOrUpdateSalonMutation, CreateOrUpdateSalonMutationVariables>;
export function useCreateOrUpdateSalonMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateSalonMutation, CreateOrUpdateSalonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateSalonMutation, CreateOrUpdateSalonMutationVariables>(CreateOrUpdateSalonDocument, options);
      }
export type CreateOrUpdateSalonMutationHookResult = ReturnType<typeof useCreateOrUpdateSalonMutation>;
export type CreateOrUpdateSalonMutationResult = Apollo.MutationResult<CreateOrUpdateSalonMutation>;
export const CreateOrUpdateUserDocument = gql`
    mutation createOrUpdateUser($input: CreateOrUpdateUserInput!) {
  createOrUpdateUser(input: $input) {
    success
    action_type
  }
}
    `;
export type CreateOrUpdateUserMutationFn = Apollo.MutationFunction<CreateOrUpdateUserMutation, CreateOrUpdateUserMutationVariables>;
export function useCreateOrUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateUserMutation, CreateOrUpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateUserMutation, CreateOrUpdateUserMutationVariables>(CreateOrUpdateUserDocument, options);
      }
export type CreateOrUpdateUserMutationHookResult = ReturnType<typeof useCreateOrUpdateUserMutation>;
export type CreateOrUpdateUserMutationResult = Apollo.MutationResult<CreateOrUpdateUserMutation>;
export const CreatePostDocument = gql`
    mutation createPost($data: AppNewsPostInput!, $locale: I18NLocaleCode) {
  createAppNewsPost(data: $data, locale: $locale) {
    data {
      id
      attributes {
        image {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export type CreatePostMutationFn = Apollo.MutationFunction<CreatePostMutation, CreatePostMutationVariables>;
export function useCreatePostMutation(baseOptions?: Apollo.MutationHookOptions<CreatePostMutation, CreatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePostMutation, CreatePostMutationVariables>(CreatePostDocument, options);
      }
export type CreatePostMutationHookResult = ReturnType<typeof useCreatePostMutation>;
export type CreatePostMutationResult = Apollo.MutationResult<CreatePostMutation>;
export const CreateProductDocument = gql`
    mutation createProduct($data: ProductInput!, $locale: I18NLocaleCode) {
  createProduct(data: $data, locale: $locale) {
    data {
      id
      attributes {
        items {
          media {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
        media {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export const CreateProductLocalizationDocument = gql`
    mutation createProductLocalization($data: ProductInput, $id: ID, $locale: I18NLocaleCode) {
  createProductLocalization(data: $data, id: $id, locale: $locale) {
    data {
      id
    }
  }
}
    `;
export type CreateProductLocalizationMutationFn = Apollo.MutationFunction<CreateProductLocalizationMutation, CreateProductLocalizationMutationVariables>;
export function useCreateProductLocalizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductLocalizationMutation, CreateProductLocalizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductLocalizationMutation, CreateProductLocalizationMutationVariables>(CreateProductLocalizationDocument, options);
      }
export type CreateProductLocalizationMutationHookResult = ReturnType<typeof useCreateProductLocalizationMutation>;
export type CreateProductLocalizationMutationResult = Apollo.MutationResult<CreateProductLocalizationMutation>;
export const CreateResourceDocument = gql`
    mutation createResource($data: AppResourceInput!) {
  createAppResource(data: $data) {
    data {
      id
      attributes {
        createdAt
        updatedAt
        description
        category
        pages
        author {
          data {
            id
            attributes {
              name
            }
          }
        }
        file {
          data {
            id
            attributes {
              url
              size
            }
          }
        }
      }
    }
  }
}
    `;
export type CreateResourceMutationFn = Apollo.MutationFunction<CreateResourceMutation, CreateResourceMutationVariables>;
export function useCreateResourceMutation(baseOptions?: Apollo.MutationHookOptions<CreateResourceMutation, CreateResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateResourceMutation, CreateResourceMutationVariables>(CreateResourceDocument, options);
      }
export type CreateResourceMutationHookResult = ReturnType<typeof useCreateResourceMutation>;
export type CreateResourceMutationResult = Apollo.MutationResult<CreateResourceMutation>;
export const DeleteCommentDocument = gql`
    mutation deleteComment($id: ID!) {
  deleteAppNewsComment(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteCommentMutationFn = Apollo.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export const DeleteEventDocument = gql`
    mutation deleteEvent($id: ID!) {
  deleteEvent(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteEventMutationFn = Apollo.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;
export function useDeleteEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, options);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export const DeleteLinkDocument = gql`
    mutation deleteLink($id: ID!) {
  deleteAppNewsPost(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteLinkMutationFn = Apollo.MutationFunction<DeleteLinkMutation, DeleteLinkMutationVariables>;
export function useDeleteLinkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLinkMutation, DeleteLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLinkMutation, DeleteLinkMutationVariables>(DeleteLinkDocument, options);
      }
export type DeleteLinkMutationHookResult = ReturnType<typeof useDeleteLinkMutation>;
export type DeleteLinkMutationResult = Apollo.MutationResult<DeleteLinkMutation>;
export const DeleteMoreSectionTypeDocument = gql`
    mutation deleteMoreSectionType($input: DeleteMoreSectionTypeInput!) {
  deleteMoreSectionType(input: $input) {
    success
  }
}
    `;
export type DeleteMoreSectionTypeMutationFn = Apollo.MutationFunction<DeleteMoreSectionTypeMutation, DeleteMoreSectionTypeMutationVariables>;
export function useDeleteMoreSectionTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMoreSectionTypeMutation, DeleteMoreSectionTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMoreSectionTypeMutation, DeleteMoreSectionTypeMutationVariables>(DeleteMoreSectionTypeDocument, options);
      }
export type DeleteMoreSectionTypeMutationHookResult = ReturnType<typeof useDeleteMoreSectionTypeMutation>;
export type DeleteMoreSectionTypeMutationResult = Apollo.MutationResult<DeleteMoreSectionTypeMutation>;
export const DeleteNotificationDocument = gql`
    mutation deleteNotification($id: ID!) {
  deleteAppNotification(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<DeleteNotificationMutation, DeleteNotificationMutationVariables>;
export function useDeleteNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNotificationMutation, DeleteNotificationMutationVariables>(DeleteNotificationDocument, options);
      }
export type DeleteNotificationMutationHookResult = ReturnType<typeof useDeleteNotificationMutation>;
export type DeleteNotificationMutationResult = Apollo.MutationResult<DeleteNotificationMutation>;
export const DeletePostDocument = gql`
    mutation deletePost($id: ID!) {
  deleteAppNewsPost(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeletePostMutationFn = Apollo.MutationFunction<DeletePostMutation, DeletePostMutationVariables>;
export function useDeletePostMutation(baseOptions?: Apollo.MutationHookOptions<DeletePostMutation, DeletePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePostMutation, DeletePostMutationVariables>(DeletePostDocument, options);
      }
export type DeletePostMutationHookResult = ReturnType<typeof useDeletePostMutation>;
export type DeletePostMutationResult = Apollo.MutationResult<DeletePostMutation>;
export const DeleteResourceDocument = gql`
    mutation deleteResource($id: ID!) {
  deleteAppResource(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteResourceMutationFn = Apollo.MutationFunction<DeleteResourceMutation, DeleteResourceMutationVariables>;
export function useDeleteResourceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteResourceMutation, DeleteResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteResourceMutation, DeleteResourceMutationVariables>(DeleteResourceDocument, options);
      }
export type DeleteResourceMutationHookResult = ReturnType<typeof useDeleteResourceMutation>;
export type DeleteResourceMutationResult = Apollo.MutationResult<DeleteResourceMutation>;
export const DeleteTranslationsDocument = gql`
    mutation deleteTranslations($input: DeleteTranslationInput!) {
  deleteTranslations(input: $input) {
    success
  }
}
    `;
export type DeleteTranslationsMutationFn = Apollo.MutationFunction<DeleteTranslationsMutation, DeleteTranslationsMutationVariables>;
export function useDeleteTranslationsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTranslationsMutation, DeleteTranslationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTranslationsMutation, DeleteTranslationsMutationVariables>(DeleteTranslationsDocument, options);
      }
export type DeleteTranslationsMutationHookResult = ReturnType<typeof useDeleteTranslationsMutation>;
export type DeleteTranslationsMutationResult = Apollo.MutationResult<DeleteTranslationsMutation>;
export const DeleteUserDocument = gql`
    mutation deleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    success
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export const DeleteProductDocument = gql`
    mutation deleteProduct($id: ID!) {
  deleteProduct(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export const LoginDocument = gql`
    mutation login($input: UsersPermissionsLoginInput!) {
  login(input: $input) {
    jwt
    user {
      id
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export const ReorderLinksDocument = gql`
    mutation reorderLinks($input: ReorderLinksInput!) {
  reorderLinks(input: $input) {
    success
  }
}
    `;
export type ReorderLinksMutationFn = Apollo.MutationFunction<ReorderLinksMutation, ReorderLinksMutationVariables>;
export function useReorderLinksMutation(baseOptions?: Apollo.MutationHookOptions<ReorderLinksMutation, ReorderLinksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderLinksMutation, ReorderLinksMutationVariables>(ReorderLinksDocument, options);
      }
export type ReorderLinksMutationHookResult = ReturnType<typeof useReorderLinksMutation>;
export type ReorderLinksMutationResult = Apollo.MutationResult<ReorderLinksMutation>;
export const ReorderMoreSectionDocument = gql`
    mutation reorderMoreSection($input: ReorderMoreSectionInput!) {
  reorderMoreSection(input: $input) {
    success
  }
}
    `;
export type ReorderMoreSectionMutationFn = Apollo.MutationFunction<ReorderMoreSectionMutation, ReorderMoreSectionMutationVariables>;
export function useReorderMoreSectionMutation(baseOptions?: Apollo.MutationHookOptions<ReorderMoreSectionMutation, ReorderMoreSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderMoreSectionMutation, ReorderMoreSectionMutationVariables>(ReorderMoreSectionDocument, options);
      }
export type ReorderMoreSectionMutationHookResult = ReturnType<typeof useReorderMoreSectionMutation>;
export type ReorderMoreSectionMutationResult = Apollo.MutationResult<ReorderMoreSectionMutation>;
export const SendPasswordDocument = gql`
    mutation sendPassword($input: SendPasswordInput!) {
  sendPassword(input: $input) {
    success
  }
}
    `;
export type SendPasswordMutationFn = Apollo.MutationFunction<SendPasswordMutation, SendPasswordMutationVariables>;
export function useSendPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SendPasswordMutation, SendPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPasswordMutation, SendPasswordMutationVariables>(SendPasswordDocument, options);
      }
export type SendPasswordMutationHookResult = ReturnType<typeof useSendPasswordMutation>;
export type SendPasswordMutationResult = Apollo.MutationResult<SendPasswordMutation>;
export const UpdateAdminDocument = gql`
    mutation updateAdmin($data: UsersPermissionsUserInput!, $id: ID!) {
  updateUsersPermissionsUser(data: $data, id: $id) {
    data {
      id
    }
  }
}
    `;
export type UpdateAdminMutationFn = Apollo.MutationFunction<UpdateAdminMutation, UpdateAdminMutationVariables>;
export function useUpdateAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminMutation, UpdateAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdminMutation, UpdateAdminMutationVariables>(UpdateAdminDocument, options);
      }
export type UpdateAdminMutationHookResult = ReturnType<typeof useUpdateAdminMutation>;
export type UpdateAdminMutationResult = Apollo.MutationResult<UpdateAdminMutation>;
export const UpdateDistributorEntityDocument = gql`
    mutation updateDistributorEntity($id: ID!, $input: DistributorInput!) {
  updateDistributor(id: $id, data: $input) {
    data {
      id
      attributes {
        name
        region {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export type UpdateDistributorEntityMutationFn = Apollo.MutationFunction<UpdateDistributorEntityMutation, UpdateDistributorEntityMutationVariables>;
export function useUpdateDistributorEntityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDistributorEntityMutation, UpdateDistributorEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDistributorEntityMutation, UpdateDistributorEntityMutationVariables>(UpdateDistributorEntityDocument, options);
      }
export type UpdateDistributorEntityMutationHookResult = ReturnType<typeof useUpdateDistributorEntityMutation>;
export type UpdateDistributorEntityMutationResult = Apollo.MutationResult<UpdateDistributorEntityMutation>;
export const UpdateEventDocument = gql`
    mutation updateEvent($data: EventInput!, $id: ID!) {
  updateEvent(data: $data, id: $id) {
    data {
      id
      attributes {
        media {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export const UpdateLinkDocument = gql`
    mutation updateLink($data: AppNewsPostInput!, $id: ID!) {
  updateAppNewsPost(data: $data, id: $id) {
    data {
      id
      attributes {
        image {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export type UpdateLinkMutationFn = Apollo.MutationFunction<UpdateLinkMutation, UpdateLinkMutationVariables>;
export function useUpdateLinkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLinkMutation, UpdateLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLinkMutation, UpdateLinkMutationVariables>(UpdateLinkDocument, options);
      }
export type UpdateLinkMutationHookResult = ReturnType<typeof useUpdateLinkMutation>;
export type UpdateLinkMutationResult = Apollo.MutationResult<UpdateLinkMutation>;
export const UpdateMoreCoversDocument = gql`
    mutation updateMoreCovers($input: AppMoreSectionInput!) {
  updateAppMoreSection(data: $input) {
    data {
      id
    }
  }
}
    `;
export type UpdateMoreCoversMutationFn = Apollo.MutationFunction<UpdateMoreCoversMutation, UpdateMoreCoversMutationVariables>;
export function useUpdateMoreCoversMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMoreCoversMutation, UpdateMoreCoversMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMoreCoversMutation, UpdateMoreCoversMutationVariables>(UpdateMoreCoversDocument, options);
      }
export type UpdateMoreCoversMutationHookResult = ReturnType<typeof useUpdateMoreCoversMutation>;
export type UpdateMoreCoversMutationResult = Apollo.MutationResult<UpdateMoreCoversMutation>;
export const UpdateMoreSectionTypesDocument = gql`
    mutation updateMoreSectionTypes($data: AppMoreSectionTypeInput!, $id: ID!) {
  updateAppMoreSectionType(data: $data, id: $id) {
    data {
      id
    }
  }
}
    `;
export type UpdateMoreSectionTypesMutationFn = Apollo.MutationFunction<UpdateMoreSectionTypesMutation, UpdateMoreSectionTypesMutationVariables>;
export function useUpdateMoreSectionTypesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMoreSectionTypesMutation, UpdateMoreSectionTypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMoreSectionTypesMutation, UpdateMoreSectionTypesMutationVariables>(UpdateMoreSectionTypesDocument, options);
      }
export type UpdateMoreSectionTypesMutationHookResult = ReturnType<typeof useUpdateMoreSectionTypesMutation>;
export type UpdateMoreSectionTypesMutationResult = Apollo.MutationResult<UpdateMoreSectionTypesMutation>;
export const UpdateNotificationDocument = gql`
    mutation updateNotification($data: AppNotificationInput!, $id: ID!) {
  updateAppNotification(data: $data, id: $id) {
    data {
      id
    }
  }
}
    `;
export type UpdateNotificationMutationFn = Apollo.MutationFunction<UpdateNotificationMutation, UpdateNotificationMutationVariables>;
export function useUpdateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationMutation, UpdateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationMutation, UpdateNotificationMutationVariables>(UpdateNotificationDocument, options);
      }
export type UpdateNotificationMutationHookResult = ReturnType<typeof useUpdateNotificationMutation>;
export type UpdateNotificationMutationResult = Apollo.MutationResult<UpdateNotificationMutation>;
export const UpdatePostDocument = gql`
    mutation updatePost($data: AppNewsPostInput!, $id: ID!, $locale: I18NLocaleCode) {
  updateAppNewsPost(data: $data, id: $id, locale: $locale) {
    data {
      id
      attributes {
        image {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export type UpdatePostMutationFn = Apollo.MutationFunction<UpdatePostMutation, UpdatePostMutationVariables>;
export function useUpdatePostMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostMutation, UpdatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePostMutation, UpdatePostMutationVariables>(UpdatePostDocument, options);
      }
export type UpdatePostMutationHookResult = ReturnType<typeof useUpdatePostMutation>;
export type UpdatePostMutationResult = Apollo.MutationResult<UpdatePostMutation>;
export const UpdateProductDocument = gql`
    mutation updateProduct($data: ProductInput!, $id: ID!, $locale: I18NLocaleCode) {
  updateProduct(data: $data, id: $id, locale: $locale) {
    data {
      id
      attributes {
        locale
        items {
          media {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
        media {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export const UpdateResourceDocument = gql`
    mutation updateResource($data: AppResourceInput!, $id: ID!) {
  updateAppResource(data: $data, id: $id) {
    data {
      id
      attributes {
        createdAt
        updatedAt
        description
        category
        pages
        author {
          data {
            id
            attributes {
              name
            }
          }
        }
        file {
          data {
            id
            attributes {
              url
              size
            }
          }
        }
      }
    }
  }
}
    `;
export type UpdateResourceMutationFn = Apollo.MutationFunction<UpdateResourceMutation, UpdateResourceMutationVariables>;
export function useUpdateResourceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateResourceMutation, UpdateResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateResourceMutation, UpdateResourceMutationVariables>(UpdateResourceDocument, options);
      }
export type UpdateResourceMutationHookResult = ReturnType<typeof useUpdateResourceMutation>;
export type UpdateResourceMutationResult = Apollo.MutationResult<UpdateResourceMutation>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: AppUpdateProfileDataInput!) {
  appUpdateProfileData(input: $input) {
    success
    profileData {
      avatar {
        data {
          attributes {
            url
          }
        }
      }
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export const CountUsersDocument = gql`
    query countUsers($filter: UsersPermissionsUserFiltersInput, $pagination: PaginationArg) {
  usersPermissionsUsers(filters: $filter, pagination: $pagination) {
    meta {
      pagination {
        total
      }
    }
  }
}
    `;
export function useCountUsersQuery(baseOptions?: Apollo.QueryHookOptions<CountUsersQuery, CountUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountUsersQuery, CountUsersQueryVariables>(CountUsersDocument, options);
      }
export function useCountUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountUsersQuery, CountUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountUsersQuery, CountUsersQueryVariables>(CountUsersDocument, options);
        }
export type CountUsersQueryHookResult = ReturnType<typeof useCountUsersQuery>;
export type CountUsersLazyQueryHookResult = ReturnType<typeof useCountUsersLazyQuery>;
export type CountUsersQueryResult = Apollo.QueryResult<CountUsersQuery, CountUsersQueryVariables>;
export const GetActivityLogDocument = gql`
    query getActivityLog($input: GetAdminActivityLogInput!) {
  getAdminActivityLog(input: $input) {
    total
    data {
      type
      action
      createdAt
      action_for
      user {
        name
      }
    }
  }
}
    `;
export function useGetActivityLogQuery(baseOptions: Apollo.QueryHookOptions<GetActivityLogQuery, GetActivityLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivityLogQuery, GetActivityLogQueryVariables>(GetActivityLogDocument, options);
      }
export function useGetActivityLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityLogQuery, GetActivityLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivityLogQuery, GetActivityLogQueryVariables>(GetActivityLogDocument, options);
        }
export type GetActivityLogQueryHookResult = ReturnType<typeof useGetActivityLogQuery>;
export type GetActivityLogLazyQueryHookResult = ReturnType<typeof useGetActivityLogLazyQuery>;
export type GetActivityLogQueryResult = Apollo.QueryResult<GetActivityLogQuery, GetActivityLogQueryVariables>;
export const GetActivityLogActionsForDocument = gql`
    query getActivityLogActionsFor($input: GetAdminActivityLogInput!) {
  getAdminActivityLog(input: $input) {
    data {
      action_for
    }
  }
}
    `;
export function useGetActivityLogActionsForQuery(baseOptions: Apollo.QueryHookOptions<GetActivityLogActionsForQuery, GetActivityLogActionsForQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivityLogActionsForQuery, GetActivityLogActionsForQueryVariables>(GetActivityLogActionsForDocument, options);
      }
export function useGetActivityLogActionsForLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityLogActionsForQuery, GetActivityLogActionsForQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivityLogActionsForQuery, GetActivityLogActionsForQueryVariables>(GetActivityLogActionsForDocument, options);
        }
export type GetActivityLogActionsForQueryHookResult = ReturnType<typeof useGetActivityLogActionsForQuery>;
export type GetActivityLogActionsForLazyQueryHookResult = ReturnType<typeof useGetActivityLogActionsForLazyQuery>;
export type GetActivityLogActionsForQueryResult = Apollo.QueryResult<GetActivityLogActionsForQuery, GetActivityLogActionsForQueryVariables>;
export const GetActivityLogUsersDocument = gql`
    query getActivityLogUsers($input: GetAdminActivityLogInput!) {
  getAdminActivityLog(input: $input) {
    data {
      user {
        name
      }
    }
  }
}
    `;
export function useGetActivityLogUsersQuery(baseOptions: Apollo.QueryHookOptions<GetActivityLogUsersQuery, GetActivityLogUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivityLogUsersQuery, GetActivityLogUsersQueryVariables>(GetActivityLogUsersDocument, options);
      }
export function useGetActivityLogUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityLogUsersQuery, GetActivityLogUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivityLogUsersQuery, GetActivityLogUsersQueryVariables>(GetActivityLogUsersDocument, options);
        }
export type GetActivityLogUsersQueryHookResult = ReturnType<typeof useGetActivityLogUsersQuery>;
export type GetActivityLogUsersLazyQueryHookResult = ReturnType<typeof useGetActivityLogUsersLazyQuery>;
export type GetActivityLogUsersQueryResult = Apollo.QueryResult<GetActivityLogUsersQuery, GetActivityLogUsersQueryVariables>;
export const GetDscEntitiesDocument = gql`
    query getDSCEntities($filters: DscFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  dscs(pagination: $pagination, sort: $sort, filters: $filters) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;
export function useGetDscEntitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetDscEntitiesQuery, GetDscEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDscEntitiesQuery, GetDscEntitiesQueryVariables>(GetDscEntitiesDocument, options);
      }
export function useGetDscEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDscEntitiesQuery, GetDscEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDscEntitiesQuery, GetDscEntitiesQueryVariables>(GetDscEntitiesDocument, options);
        }
export type GetDscEntitiesQueryHookResult = ReturnType<typeof useGetDscEntitiesQuery>;
export type GetDscEntitiesLazyQueryHookResult = ReturnType<typeof useGetDscEntitiesLazyQuery>;
export type GetDscEntitiesQueryResult = Apollo.QueryResult<GetDscEntitiesQuery, GetDscEntitiesQueryVariables>;
export const GetDscEntitiesWithUserIdDocument = gql`
    query getDSCEntitiesWithUserId($pagination: PaginationArg, $sort: [String]) {
  dscs(pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        name
        users {
          data {
            id
          }
        }
      }
    }
  }
}
    `;
export function useGetDscEntitiesWithUserIdQuery(baseOptions?: Apollo.QueryHookOptions<GetDscEntitiesWithUserIdQuery, GetDscEntitiesWithUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDscEntitiesWithUserIdQuery, GetDscEntitiesWithUserIdQueryVariables>(GetDscEntitiesWithUserIdDocument, options);
      }
export function useGetDscEntitiesWithUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDscEntitiesWithUserIdQuery, GetDscEntitiesWithUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDscEntitiesWithUserIdQuery, GetDscEntitiesWithUserIdQueryVariables>(GetDscEntitiesWithUserIdDocument, options);
        }
export type GetDscEntitiesWithUserIdQueryHookResult = ReturnType<typeof useGetDscEntitiesWithUserIdQuery>;
export type GetDscEntitiesWithUserIdLazyQueryHookResult = ReturnType<typeof useGetDscEntitiesWithUserIdLazyQuery>;
export type GetDscEntitiesWithUserIdQueryResult = Apollo.QueryResult<GetDscEntitiesWithUserIdQuery, GetDscEntitiesWithUserIdQueryVariables>;
export const GetDashboardDocument = gql`
    query getDashboard($input: AdminDashboardInput!) {
  getDashboard(input: $input) {
    overview {
      overviewEvents {
        count
        difference
        isPositiveDifference
      }
      overviewProducts {
        count
        difference
        isPositiveDifference
      }
      overviewDscs {
        count
        difference
        isPositiveDifference
      }
      overviewSalesManagers {
        count
        difference
        isPositiveDifference
      }
      overviewDistributors {
        count
        difference
        isPositiveDifference
      }
      overviewPosts {
        count
        difference
        isPositiveDifference
      }
    }
    activity {
      totalProductsCatalogViews {
        count
        difference
        isPositiveDifference
      }
      totalEventsViews {
        count
        difference
        isPositiveDifference
      }
      totalNewsViews {
        count
        difference
        isPositiveDifference
      }
      totalComments {
        count
        difference
        isPositiveDifference
      }
      totalPDPViews {
        count
        difference
        isPositiveDifference
      }
      totalLikes {
        count
        difference
        isPositiveDifference
      }
      totalChats {
        count
        difference
        isPositiveDifference
      }
      newUsers {
        count
        difference
        isPositiveDifference
      }
    }
    topRated {
      mostPopularPosts {
        count
        name
      }
      mostPopularLinks {
        count
        name
      }
      mostLikedPosts {
        count
        name
      }
    }
  }
}
    `;
export function useGetDashboardQuery(baseOptions: Apollo.QueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, options);
      }
export function useGetDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, options);
        }
export type GetDashboardQueryHookResult = ReturnType<typeof useGetDashboardQuery>;
export type GetDashboardLazyQueryHookResult = ReturnType<typeof useGetDashboardLazyQuery>;
export type GetDashboardQueryResult = Apollo.QueryResult<GetDashboardQuery, GetDashboardQueryVariables>;
export const GetDistributorEntitiesDocument = gql`
    query getDistributorEntities($pagination: PaginationArg, $filter: DistributorFiltersInput, $sort: [String]) {
  distributors(pagination: $pagination, filters: $filter, sort: $sort) {
    data {
      id
      attributes {
        name
        region {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
    meta {
      pagination {
        total
      }
    }
  }
}
    `;
export function useGetDistributorEntitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetDistributorEntitiesQuery, GetDistributorEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDistributorEntitiesQuery, GetDistributorEntitiesQueryVariables>(GetDistributorEntitiesDocument, options);
      }
export function useGetDistributorEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDistributorEntitiesQuery, GetDistributorEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDistributorEntitiesQuery, GetDistributorEntitiesQueryVariables>(GetDistributorEntitiesDocument, options);
        }
export type GetDistributorEntitiesQueryHookResult = ReturnType<typeof useGetDistributorEntitiesQuery>;
export type GetDistributorEntitiesLazyQueryHookResult = ReturnType<typeof useGetDistributorEntitiesLazyQuery>;
export type GetDistributorEntitiesQueryResult = Apollo.QueryResult<GetDistributorEntitiesQuery, GetDistributorEntitiesQueryVariables>;
export const GetDistributorsDocument = gql`
    query getDistributors($pagination: PaginationArg, $filter: UsersPermissionsUserFiltersInput, $sort: [String]) {
  usersPermissionsUsers(pagination: $pagination, filters: $filter, sort: $sort) {
    data {
      id
      attributes {
        distributor {
          data {
            id
            attributes {
              name
            }
          }
        }
        sales_manager {
          data {
            attributes {
              distributor {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
            }
          }
        }
        dsc {
          data {
            attributes {
              sales_manager {
                data {
                  attributes {
                    distributor {
                      data {
                        attributes {
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetDistributorsQuery(baseOptions?: Apollo.QueryHookOptions<GetDistributorsQuery, GetDistributorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDistributorsQuery, GetDistributorsQueryVariables>(GetDistributorsDocument, options);
      }
export function useGetDistributorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDistributorsQuery, GetDistributorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDistributorsQuery, GetDistributorsQueryVariables>(GetDistributorsDocument, options);
        }
export type GetDistributorsQueryHookResult = ReturnType<typeof useGetDistributorsQuery>;
export type GetDistributorsLazyQueryHookResult = ReturnType<typeof useGetDistributorsLazyQuery>;
export type GetDistributorsQueryResult = Apollo.QueryResult<GetDistributorsQuery, GetDistributorsQueryVariables>;
export const GetEnumsDocument = gql`
    query getEnums($input: GetEnumInput!) {
  getEnum(input: $input) {
    enum
  }
}
    `;
export function useGetEnumsQuery(baseOptions: Apollo.QueryHookOptions<GetEnumsQuery, GetEnumsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEnumsQuery, GetEnumsQueryVariables>(GetEnumsDocument, options);
      }
export function useGetEnumsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnumsQuery, GetEnumsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEnumsQuery, GetEnumsQueryVariables>(GetEnumsDocument, options);
        }
export type GetEnumsQueryHookResult = ReturnType<typeof useGetEnumsQuery>;
export type GetEnumsLazyQueryHookResult = ReturnType<typeof useGetEnumsLazyQuery>;
export type GetEnumsQueryResult = Apollo.QueryResult<GetEnumsQuery, GetEnumsQueryVariables>;
export const GetEventDocument = gql`
    query getEvent($id: ID!) {
  event(id: $id) {
    data {
      id
      attributes {
        title
        description
        startAt
        endAt
        youtubeLink
        eventLink
        address
        timeZone
        createdAt
        updatedAt
        media {
          data {
            id
            attributes {
              url
            }
          }
        }
        distributors {
          data {
            id
            attributes {
              name
            }
          }
        }
        regions {
          data {
            id
            attributes {
              name
            }
          }
        }
        dscs {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetEventQuery(baseOptions: Apollo.QueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, options);
      }
export function useGetEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, options);
        }
export type GetEventQueryHookResult = ReturnType<typeof useGetEventQuery>;
export type GetEventLazyQueryHookResult = ReturnType<typeof useGetEventLazyQuery>;
export type GetEventQueryResult = Apollo.QueryResult<GetEventQuery, GetEventQueryVariables>;
export const GetEventAddressesDocument = gql`
    query getEventAddresses($input: EventsInput!) {
  events(input: $input) {
    data {
      id
      address
    }
  }
}
    `;
export function useGetEventAddressesQuery(baseOptions: Apollo.QueryHookOptions<GetEventAddressesQuery, GetEventAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventAddressesQuery, GetEventAddressesQueryVariables>(GetEventAddressesDocument, options);
      }
export function useGetEventAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventAddressesQuery, GetEventAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventAddressesQuery, GetEventAddressesQueryVariables>(GetEventAddressesDocument, options);
        }
export type GetEventAddressesQueryHookResult = ReturnType<typeof useGetEventAddressesQuery>;
export type GetEventAddressesLazyQueryHookResult = ReturnType<typeof useGetEventAddressesLazyQuery>;
export type GetEventAddressesQueryResult = Apollo.QueryResult<GetEventAddressesQuery, GetEventAddressesQueryVariables>;
export const GetEventListDocument = gql`
    query getEventList($input: EventsInput!) {
  events(input: $input) {
    total
    data {
      id
      title
      address
      description
      startAt
      endAt
    }
  }
}
    `;
export function useGetEventListQuery(baseOptions: Apollo.QueryHookOptions<GetEventListQuery, GetEventListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventListQuery, GetEventListQueryVariables>(GetEventListDocument, options);
      }
export function useGetEventListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventListQuery, GetEventListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventListQuery, GetEventListQueryVariables>(GetEventListDocument, options);
        }
export type GetEventListQueryHookResult = ReturnType<typeof useGetEventListQuery>;
export type GetEventListLazyQueryHookResult = ReturnType<typeof useGetEventListLazyQuery>;
export type GetEventListQueryResult = Apollo.QueryResult<GetEventListQuery, GetEventListQueryVariables>;
export const GetEventTitlesDocument = gql`
    query getEventTitles($input: EventsInput!) {
  events(input: $input) {
    data {
      id
      title
    }
  }
}
    `;
export function useGetEventTitlesQuery(baseOptions: Apollo.QueryHookOptions<GetEventTitlesQuery, GetEventTitlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventTitlesQuery, GetEventTitlesQueryVariables>(GetEventTitlesDocument, options);
      }
export function useGetEventTitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventTitlesQuery, GetEventTitlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventTitlesQuery, GetEventTitlesQueryVariables>(GetEventTitlesDocument, options);
        }
export type GetEventTitlesQueryHookResult = ReturnType<typeof useGetEventTitlesQuery>;
export type GetEventTitlesLazyQueryHookResult = ReturnType<typeof useGetEventTitlesLazyQuery>;
export type GetEventTitlesQueryResult = Apollo.QueryResult<GetEventTitlesQuery, GetEventTitlesQueryVariables>;
export const GetGuestsListDocument = gql`
    query getGuestsList($pagination: PaginationArg, $filter: UsersPermissionsUserFiltersInput, $sort: [String]) {
  usersPermissionsUsers(pagination: $pagination, filters: $filter, sort: $sort) {
    data {
      id
      attributes {
        blocked
        isAppActivated
        name
        email
        guest {
          data {
            id
            attributes {
              name
              guest_type
            }
          }
        }
      }
    }
    meta {
      pagination {
        total
      }
    }
  }
}
    `;
export function useGetGuestsListQuery(baseOptions?: Apollo.QueryHookOptions<GetGuestsListQuery, GetGuestsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGuestsListQuery, GetGuestsListQueryVariables>(GetGuestsListDocument, options);
      }
export function useGetGuestsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGuestsListQuery, GetGuestsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGuestsListQuery, GetGuestsListQueryVariables>(GetGuestsListDocument, options);
        }
export type GetGuestsListQueryHookResult = ReturnType<typeof useGetGuestsListQuery>;
export type GetGuestsListLazyQueryHookResult = ReturnType<typeof useGetGuestsListLazyQuery>;
export type GetGuestsListQueryResult = Apollo.QueryResult<GetGuestsListQuery, GetGuestsListQueryVariables>;
export const GetLeaderboardDocument = gql`
    query getLeaderboard($input: AppGetLeaderboardInput!) {
  appGetLeaderboard(input: $input) {
    participants
    user_place
    leaderboard {
      name
      blocked
      region
      country
      sales
      total_new_door
      haircare_sales
      color_me_sales
      combined_sales
    }
  }
}
    `;
export function useGetLeaderboardQuery(baseOptions: Apollo.QueryHookOptions<GetLeaderboardQuery, GetLeaderboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLeaderboardQuery, GetLeaderboardQueryVariables>(GetLeaderboardDocument, options);
      }
export function useGetLeaderboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLeaderboardQuery, GetLeaderboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLeaderboardQuery, GetLeaderboardQueryVariables>(GetLeaderboardDocument, options);
        }
export type GetLeaderboardQueryHookResult = ReturnType<typeof useGetLeaderboardQuery>;
export type GetLeaderboardLazyQueryHookResult = ReturnType<typeof useGetLeaderboardLazyQuery>;
export type GetLeaderboardQueryResult = Apollo.QueryResult<GetLeaderboardQuery, GetLeaderboardQueryVariables>;
export const GetLinkDocument = gql`
    query getLink($id: ID!) {
  appNewsPost(id: $id) {
    data {
      attributes {
        title
        type
        body
        link
        createdAt
        updatedAt
        more_section_type {
          data {
            id
            attributes {
              label
              name
            }
          }
        }
        author {
          data {
            attributes {
              name
            }
          }
        }
        image {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetLinkQuery(baseOptions: Apollo.QueryHookOptions<GetLinkQuery, GetLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLinkQuery, GetLinkQueryVariables>(GetLinkDocument, options);
      }
export function useGetLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLinkQuery, GetLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLinkQuery, GetLinkQueryVariables>(GetLinkDocument, options);
        }
export type GetLinkQueryHookResult = ReturnType<typeof useGetLinkQuery>;
export type GetLinkLazyQueryHookResult = ReturnType<typeof useGetLinkLazyQuery>;
export type GetLinkQueryResult = Apollo.QueryResult<GetLinkQuery, GetLinkQueryVariables>;
export const GetLinkAmountDocument = gql`
    query getLinkAmount($filter: AppNewsPostFiltersInput!) {
  appNewsPosts(filters: $filter) {
    meta {
      pagination {
        total
      }
    }
  }
}
    `;
export function useGetLinkAmountQuery(baseOptions: Apollo.QueryHookOptions<GetLinkAmountQuery, GetLinkAmountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLinkAmountQuery, GetLinkAmountQueryVariables>(GetLinkAmountDocument, options);
      }
export function useGetLinkAmountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLinkAmountQuery, GetLinkAmountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLinkAmountQuery, GetLinkAmountQueryVariables>(GetLinkAmountDocument, options);
        }
export type GetLinkAmountQueryHookResult = ReturnType<typeof useGetLinkAmountQuery>;
export type GetLinkAmountLazyQueryHookResult = ReturnType<typeof useGetLinkAmountLazyQuery>;
export type GetLinkAmountQueryResult = Apollo.QueryResult<GetLinkAmountQuery, GetLinkAmountQueryVariables>;
export const GetLinkListDocument = gql`
    query getLinkList($pagination: PaginationArg, $filter: AppNewsPostFiltersInput!, $sort: [String]) {
  appNewsPosts(pagination: $pagination, filters: $filter, sort: $sort) {
    meta {
      pagination {
        total
      }
    }
    data {
      id
      attributes {
        title
        type
        sequence
        createdAt
        more_section_type {
          data {
            id
            attributes {
              label
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetLinkListQuery(baseOptions: Apollo.QueryHookOptions<GetLinkListQuery, GetLinkListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLinkListQuery, GetLinkListQueryVariables>(GetLinkListDocument, options);
      }
export function useGetLinkListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLinkListQuery, GetLinkListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLinkListQuery, GetLinkListQueryVariables>(GetLinkListDocument, options);
        }
export type GetLinkListQueryHookResult = ReturnType<typeof useGetLinkListQuery>;
export type GetLinkListLazyQueryHookResult = ReturnType<typeof useGetLinkListLazyQuery>;
export type GetLinkListQueryResult = Apollo.QueryResult<GetLinkListQuery, GetLinkListQueryVariables>;
export const GetLinkTitlesDocument = gql`
    query getLinkTitles($pagination: PaginationArg, $filter: AppNewsPostFiltersInput!, $sort: [String]) {
  appNewsPosts(pagination: $pagination, filters: $filter, sort: $sort) {
    data {
      id
      attributes {
        title
      }
    }
  }
}
    `;
export function useGetLinkTitlesQuery(baseOptions: Apollo.QueryHookOptions<GetLinkTitlesQuery, GetLinkTitlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLinkTitlesQuery, GetLinkTitlesQueryVariables>(GetLinkTitlesDocument, options);
      }
export function useGetLinkTitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLinkTitlesQuery, GetLinkTitlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLinkTitlesQuery, GetLinkTitlesQueryVariables>(GetLinkTitlesDocument, options);
        }
export type GetLinkTitlesQueryHookResult = ReturnType<typeof useGetLinkTitlesQuery>;
export type GetLinkTitlesLazyQueryHookResult = ReturnType<typeof useGetLinkTitlesLazyQuery>;
export type GetLinkTitlesQueryResult = Apollo.QueryResult<GetLinkTitlesQuery, GetLinkTitlesQueryVariables>;
export const GetMoreSectionCoversDocument = gql`
    query getMoreSectionCovers {
  appMoreSection {
    data {
      id
      attributes {
        events_cover {
          data {
            id
            attributes {
              url
            }
          }
        }
        matchmaking_cover {
          data {
            id
            attributes {
              url
            }
          }
        }
        q_links_cover {
          data {
            id
            attributes {
              url
            }
          }
        }
        resourses_cover {
          data {
            id
            attributes {
              url
            }
          }
        }
        events_cover_tablet {
          data {
            id
            attributes {
              url
            }
          }
        }
        matchmaking_cover_tablet {
          data {
            id
            attributes {
              url
            }
          }
        }
        q_links_cover_tablet {
          data {
            id
            attributes {
              url
            }
          }
        }
        resourses_cover_tablet {
          data {
            id
            attributes {
              url
            }
          }
        }
        events_cover_landscape {
          data {
            id
            attributes {
              url
            }
          }
        }
        matchmaking_cover_landscape {
          data {
            id
            attributes {
              url
            }
          }
        }
        q_links_cover_landscape {
          data {
            id
            attributes {
              url
            }
          }
        }
        resourses_cover_landscape {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetMoreSectionCoversQuery(baseOptions?: Apollo.QueryHookOptions<GetMoreSectionCoversQuery, GetMoreSectionCoversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMoreSectionCoversQuery, GetMoreSectionCoversQueryVariables>(GetMoreSectionCoversDocument, options);
      }
export function useGetMoreSectionCoversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMoreSectionCoversQuery, GetMoreSectionCoversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMoreSectionCoversQuery, GetMoreSectionCoversQueryVariables>(GetMoreSectionCoversDocument, options);
        }
export type GetMoreSectionCoversQueryHookResult = ReturnType<typeof useGetMoreSectionCoversQuery>;
export type GetMoreSectionCoversLazyQueryHookResult = ReturnType<typeof useGetMoreSectionCoversLazyQuery>;
export type GetMoreSectionCoversQueryResult = Apollo.QueryResult<GetMoreSectionCoversQuery, GetMoreSectionCoversQueryVariables>;
export const GetMoreSectionTypesDocument = gql`
    query getMoreSectionTypes($sort: [String], $pagination: PaginationArg, $region: [String!]) {
  appMoreSectionTypes(
    sort: $sort
    pagination: $pagination
    filters: {regions: {name: {in: $region}}}
  ) {
    data {
      id
      attributes {
        name
        label
        sequence
        isHidden
        regions {
          data {
            id
            attributes {
              name
            }
          }
        }
        mobile_cover {
          data {
            id
            attributes {
              url
            }
          }
        }
        tablet_cover {
          data {
            id
            attributes {
              url
            }
          }
        }
        landscape_cover {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetMoreSectionTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetMoreSectionTypesQuery, GetMoreSectionTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMoreSectionTypesQuery, GetMoreSectionTypesQueryVariables>(GetMoreSectionTypesDocument, options);
      }
export function useGetMoreSectionTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMoreSectionTypesQuery, GetMoreSectionTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMoreSectionTypesQuery, GetMoreSectionTypesQueryVariables>(GetMoreSectionTypesDocument, options);
        }
export type GetMoreSectionTypesQueryHookResult = ReturnType<typeof useGetMoreSectionTypesQuery>;
export type GetMoreSectionTypesLazyQueryHookResult = ReturnType<typeof useGetMoreSectionTypesLazyQuery>;
export type GetMoreSectionTypesQueryResult = Apollo.QueryResult<GetMoreSectionTypesQuery, GetMoreSectionTypesQueryVariables>;
export const GetNotificationDocument = gql`
    query getNotification($id: ID!) {
  appNotification(id: $id) {
    data {
      attributes {
        title
        body
        pushAt
        createdAt
        updatedAt
        timeZone
        regions {
          data {
            id
            attributes {
              name
            }
          }
        }
        distributors {
          data {
            id
            attributes {
              name
            }
          }
        }
        user {
          data {
            id
            attributes {
              name
            }
          }
        }
        event {
          data {
            id
            attributes {
              title
            }
          }
        }
        type {
          data {
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetNotificationQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationQuery, GetNotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationQuery, GetNotificationQueryVariables>(GetNotificationDocument, options);
      }
export function useGetNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationQuery, GetNotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationQuery, GetNotificationQueryVariables>(GetNotificationDocument, options);
        }
export type GetNotificationQueryHookResult = ReturnType<typeof useGetNotificationQuery>;
export type GetNotificationLazyQueryHookResult = ReturnType<typeof useGetNotificationLazyQuery>;
export type GetNotificationQueryResult = Apollo.QueryResult<GetNotificationQuery, GetNotificationQueryVariables>;
export const GetNotificationDescriptionsDocument = gql`
    query getNotificationDescriptions($input: GetNotificationListInput!) {
  getNotificationList(input: $input) {
    total
    data {
      body
    }
  }
}
    `;
export function useGetNotificationDescriptionsQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationDescriptionsQuery, GetNotificationDescriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationDescriptionsQuery, GetNotificationDescriptionsQueryVariables>(GetNotificationDescriptionsDocument, options);
      }
export function useGetNotificationDescriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationDescriptionsQuery, GetNotificationDescriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationDescriptionsQuery, GetNotificationDescriptionsQueryVariables>(GetNotificationDescriptionsDocument, options);
        }
export type GetNotificationDescriptionsQueryHookResult = ReturnType<typeof useGetNotificationDescriptionsQuery>;
export type GetNotificationDescriptionsLazyQueryHookResult = ReturnType<typeof useGetNotificationDescriptionsLazyQuery>;
export type GetNotificationDescriptionsQueryResult = Apollo.QueryResult<GetNotificationDescriptionsQuery, GetNotificationDescriptionsQueryVariables>;
export const GetNotificationListDocument = gql`
    query getNotificationList($input: GetNotificationListInput!) {
  getNotificationList(input: $input) {
    total
    data {
      id
      title
      body
      pushAt
      createdAt
      isAutoGenerated
      type {
        id
        name
      }
    }
  }
}
    `;
export function useGetNotificationListQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationListQuery, GetNotificationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationListQuery, GetNotificationListQueryVariables>(GetNotificationListDocument, options);
      }
export function useGetNotificationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationListQuery, GetNotificationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationListQuery, GetNotificationListQueryVariables>(GetNotificationListDocument, options);
        }
export type GetNotificationListQueryHookResult = ReturnType<typeof useGetNotificationListQuery>;
export type GetNotificationListLazyQueryHookResult = ReturnType<typeof useGetNotificationListLazyQuery>;
export type GetNotificationListQueryResult = Apollo.QueryResult<GetNotificationListQuery, GetNotificationListQueryVariables>;
export const GetNotificationTitlesDocument = gql`
    query getNotificationTitles($input: GetNotificationListInput!) {
  getNotificationList(input: $input) {
    total
    data {
      title
    }
  }
}
    `;
export function useGetNotificationTitlesQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationTitlesQuery, GetNotificationTitlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationTitlesQuery, GetNotificationTitlesQueryVariables>(GetNotificationTitlesDocument, options);
      }
export function useGetNotificationTitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationTitlesQuery, GetNotificationTitlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationTitlesQuery, GetNotificationTitlesQueryVariables>(GetNotificationTitlesDocument, options);
        }
export type GetNotificationTitlesQueryHookResult = ReturnType<typeof useGetNotificationTitlesQuery>;
export type GetNotificationTitlesLazyQueryHookResult = ReturnType<typeof useGetNotificationTitlesLazyQuery>;
export type GetNotificationTitlesQueryResult = Apollo.QueryResult<GetNotificationTitlesQuery, GetNotificationTitlesQueryVariables>;
export const GetNotificationTypesDocument = gql`
    query getNotificationTypes {
  appNotificationTypes {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;
export function useGetNotificationTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationTypesQuery, GetNotificationTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationTypesQuery, GetNotificationTypesQueryVariables>(GetNotificationTypesDocument, options);
      }
export function useGetNotificationTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationTypesQuery, GetNotificationTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationTypesQuery, GetNotificationTypesQueryVariables>(GetNotificationTypesDocument, options);
        }
export type GetNotificationTypesQueryHookResult = ReturnType<typeof useGetNotificationTypesQuery>;
export type GetNotificationTypesLazyQueryHookResult = ReturnType<typeof useGetNotificationTypesLazyQuery>;
export type GetNotificationTypesQueryResult = Apollo.QueryResult<GetNotificationTypesQuery, GetNotificationTypesQueryVariables>;
export const GetPostDocument = gql`
    query getPost($filter: AppNewsPostFiltersInput!) {
  appNewsPosts(filters: $filter) {
    data {
      attributes {
        title
        tag
        body
        link
        timeZone
        locale
        localizations {
          data {
            id
            attributes {
              locale
              body
            }
          }
        }
        image {
          data {
            id
            attributes {
              formats
              url
            }
          }
        }
        regions {
          data {
            id
          }
        }
        distributors {
          data {
            id
          }
        }
        createdAt
        updatedAt
        start_at
        app_news_comments {
          data {
            id
            attributes {
              body
              title
              createdAt
              user {
                data {
                  attributes {
                    name
                  }
                }
              }
            }
          }
        }
        author {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetPostQuery(baseOptions: Apollo.QueryHookOptions<GetPostQuery, GetPostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostQuery, GetPostQueryVariables>(GetPostDocument, options);
      }
export function useGetPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostQuery, GetPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostQuery, GetPostQueryVariables>(GetPostDocument, options);
        }
export type GetPostQueryHookResult = ReturnType<typeof useGetPostQuery>;
export type GetPostLazyQueryHookResult = ReturnType<typeof useGetPostLazyQuery>;
export type GetPostQueryResult = Apollo.QueryResult<GetPostQuery, GetPostQueryVariables>;
export const GetPostAuthorsDocument = gql`
    query getPostAuthors($pagination: PaginationArg, $filter: AppNewsPostFiltersInput!, $sort: [String]) {
  appNewsPosts(pagination: $pagination, filters: $filter, sort: $sort) {
    data {
      id
      attributes {
        author {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetPostAuthorsQuery(baseOptions: Apollo.QueryHookOptions<GetPostAuthorsQuery, GetPostAuthorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostAuthorsQuery, GetPostAuthorsQueryVariables>(GetPostAuthorsDocument, options);
      }
export function useGetPostAuthorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostAuthorsQuery, GetPostAuthorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostAuthorsQuery, GetPostAuthorsQueryVariables>(GetPostAuthorsDocument, options);
        }
export type GetPostAuthorsQueryHookResult = ReturnType<typeof useGetPostAuthorsQuery>;
export type GetPostAuthorsLazyQueryHookResult = ReturnType<typeof useGetPostAuthorsLazyQuery>;
export type GetPostAuthorsQueryResult = Apollo.QueryResult<GetPostAuthorsQuery, GetPostAuthorsQueryVariables>;
export const GetPostCategoriesDocument = gql`
    query getPostCategories($pagination: PaginationArg, $filter: AppNewsPostFiltersInput!, $sort: [String]) {
  appNewsPosts(pagination: $pagination, filters: $filter, sort: $sort) {
    data {
      id
      attributes {
        tag
      }
    }
  }
}
    `;
export function useGetPostCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetPostCategoriesQuery, GetPostCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostCategoriesQuery, GetPostCategoriesQueryVariables>(GetPostCategoriesDocument, options);
      }
export function useGetPostCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostCategoriesQuery, GetPostCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostCategoriesQuery, GetPostCategoriesQueryVariables>(GetPostCategoriesDocument, options);
        }
export type GetPostCategoriesQueryHookResult = ReturnType<typeof useGetPostCategoriesQuery>;
export type GetPostCategoriesLazyQueryHookResult = ReturnType<typeof useGetPostCategoriesLazyQuery>;
export type GetPostCategoriesQueryResult = Apollo.QueryResult<GetPostCategoriesQuery, GetPostCategoriesQueryVariables>;
export const GetPostListDocument = gql`
    query getPostList($pagination: PaginationArg, $filter: AppNewsPostFiltersInput!, $sort: [String]) {
  appNewsPosts(pagination: $pagination, filters: $filter, sort: $sort) {
    meta {
      pagination {
        total
      }
    }
    data {
      id
      attributes {
        title
        tag
        createdAt
        start_at
        locale
        localizations {
          data {
            id
            attributes {
              locale
              body
            }
          }
        }
        author {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetPostListQuery(baseOptions: Apollo.QueryHookOptions<GetPostListQuery, GetPostListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostListQuery, GetPostListQueryVariables>(GetPostListDocument, options);
      }
export function useGetPostListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostListQuery, GetPostListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostListQuery, GetPostListQueryVariables>(GetPostListDocument, options);
        }
export type GetPostListQueryHookResult = ReturnType<typeof useGetPostListQuery>;
export type GetPostListLazyQueryHookResult = ReturnType<typeof useGetPostListLazyQuery>;
export type GetPostListQueryResult = Apollo.QueryResult<GetPostListQuery, GetPostListQueryVariables>;
export const GetPostTitlesDocument = gql`
    query getPostTitles($pagination: PaginationArg, $filter: AppNewsPostFiltersInput!, $sort: [String]) {
  appNewsPosts(pagination: $pagination, filters: $filter, sort: $sort) {
    data {
      id
      attributes {
        title
      }
    }
  }
}
    `;
export function useGetPostTitlesQuery(baseOptions: Apollo.QueryHookOptions<GetPostTitlesQuery, GetPostTitlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostTitlesQuery, GetPostTitlesQueryVariables>(GetPostTitlesDocument, options);
      }
export function useGetPostTitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostTitlesQuery, GetPostTitlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostTitlesQuery, GetPostTitlesQueryVariables>(GetPostTitlesDocument, options);
        }
export type GetPostTitlesQueryHookResult = ReturnType<typeof useGetPostTitlesQuery>;
export type GetPostTitlesLazyQueryHookResult = ReturnType<typeof useGetPostTitlesLazyQuery>;
export type GetPostTitlesQueryResult = Apollo.QueryResult<GetPostTitlesQuery, GetPostTitlesQueryVariables>;
export const GetPrivacyDocument = gql`
    query getPrivacy {
  appPrivacyPolicy {
    data {
      attributes {
        last_changed
        content
      }
    }
  }
}
    `;
export function useGetPrivacyQuery(baseOptions?: Apollo.QueryHookOptions<GetPrivacyQuery, GetPrivacyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrivacyQuery, GetPrivacyQueryVariables>(GetPrivacyDocument, options);
      }
export function useGetPrivacyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrivacyQuery, GetPrivacyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrivacyQuery, GetPrivacyQueryVariables>(GetPrivacyDocument, options);
        }
export type GetPrivacyQueryHookResult = ReturnType<typeof useGetPrivacyQuery>;
export type GetPrivacyLazyQueryHookResult = ReturnType<typeof useGetPrivacyLazyQuery>;
export type GetPrivacyQueryResult = Apollo.QueryResult<GetPrivacyQuery, GetPrivacyQueryVariables>;
export const GetProductDocument = gql`
    query getProduct($id: ID!, $locale: I18NLocaleCode) {
  product(id: $id, locale: $locale) {
    data {
      id
      attributes {
        createdAt
        updatedAt
        title
        descriptionTitle
        description
        brand
        defaultSize
        defaultColor
        locale
        items {
          id
          name
          size
          color
          SKU
          media {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
        media {
          data {
            attributes {
              url
            }
          }
        }
        type {
          data {
            attributes {
              name
            }
          }
        }
        benefit {
          data {
            attributes {
              name
            }
          }
        }
        localizations {
          data {
            id
            attributes {
              locale
              description
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetProductQuery(baseOptions: Apollo.QueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
      }
export function useGetProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
        }
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<typeof useGetProductLazyQuery>;
export type GetProductQueryResult = Apollo.QueryResult<GetProductQuery, GetProductQueryVariables>;
export const GetProductBenefitsDocument = gql`
    query getProductBenefits($filters: ProductBenefitFiltersInput!, $sort: [String], $pagination: PaginationArg) {
  productBenefits(filters: $filters, sort: $sort, pagination: $pagination) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;
export function useGetProductBenefitsQuery(baseOptions: Apollo.QueryHookOptions<GetProductBenefitsQuery, GetProductBenefitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductBenefitsQuery, GetProductBenefitsQueryVariables>(GetProductBenefitsDocument, options);
      }
export function useGetProductBenefitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductBenefitsQuery, GetProductBenefitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductBenefitsQuery, GetProductBenefitsQueryVariables>(GetProductBenefitsDocument, options);
        }
export type GetProductBenefitsQueryHookResult = ReturnType<typeof useGetProductBenefitsQuery>;
export type GetProductBenefitsLazyQueryHookResult = ReturnType<typeof useGetProductBenefitsLazyQuery>;
export type GetProductBenefitsQueryResult = Apollo.QueryResult<GetProductBenefitsQuery, GetProductBenefitsQueryVariables>;
export const GetProductListDocument = gql`
    query getProductList($input: ProductsInput!) {
  products(input: $input) {
    total
    data {
      id
      title
      descriptionTitle
      type {
        name
      }
      benefit {
        name
      }
      localizations {
        description
        locale
      }
    }
  }
}
    `;
export function useGetProductListQuery(baseOptions: Apollo.QueryHookOptions<GetProductListQuery, GetProductListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductListQuery, GetProductListQueryVariables>(GetProductListDocument, options);
      }
export function useGetProductListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductListQuery, GetProductListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductListQuery, GetProductListQueryVariables>(GetProductListDocument, options);
        }
export type GetProductListQueryHookResult = ReturnType<typeof useGetProductListQuery>;
export type GetProductListLazyQueryHookResult = ReturnType<typeof useGetProductListLazyQuery>;
export type GetProductListQueryResult = Apollo.QueryResult<GetProductListQuery, GetProductListQueryVariables>;
export const GetProductNamesDocument = gql`
    query getProductNames($input: ProductsInput!) {
  products(input: $input) {
    data {
      id
      title
    }
  }
}
    `;
export function useGetProductNamesQuery(baseOptions: Apollo.QueryHookOptions<GetProductNamesQuery, GetProductNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductNamesQuery, GetProductNamesQueryVariables>(GetProductNamesDocument, options);
      }
export function useGetProductNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductNamesQuery, GetProductNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductNamesQuery, GetProductNamesQueryVariables>(GetProductNamesDocument, options);
        }
export type GetProductNamesQueryHookResult = ReturnType<typeof useGetProductNamesQuery>;
export type GetProductNamesLazyQueryHookResult = ReturnType<typeof useGetProductNamesLazyQuery>;
export type GetProductNamesQueryResult = Apollo.QueryResult<GetProductNamesQuery, GetProductNamesQueryVariables>;
export const GetProductTypesDocument = gql`
    query getProductTypes($filters: ProductTypeFiltersInput!, $sort: [String], $pagination: PaginationArg) {
  productTypes(filters: $filters, sort: $sort, pagination: $pagination) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;
export function useGetProductTypesQuery(baseOptions: Apollo.QueryHookOptions<GetProductTypesQuery, GetProductTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductTypesQuery, GetProductTypesQueryVariables>(GetProductTypesDocument, options);
      }
export function useGetProductTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductTypesQuery, GetProductTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductTypesQuery, GetProductTypesQueryVariables>(GetProductTypesDocument, options);
        }
export type GetProductTypesQueryHookResult = ReturnType<typeof useGetProductTypesQuery>;
export type GetProductTypesLazyQueryHookResult = ReturnType<typeof useGetProductTypesLazyQuery>;
export type GetProductTypesQueryResult = Apollo.QueryResult<GetProductTypesQuery, GetProductTypesQueryVariables>;
export const GetRegionsDocument = gql`
    query getRegions {
  regions {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;
export function useGetRegionsQuery(baseOptions?: Apollo.QueryHookOptions<GetRegionsQuery, GetRegionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegionsQuery, GetRegionsQueryVariables>(GetRegionsDocument, options);
      }
export function useGetRegionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegionsQuery, GetRegionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegionsQuery, GetRegionsQueryVariables>(GetRegionsDocument, options);
        }
export type GetRegionsQueryHookResult = ReturnType<typeof useGetRegionsQuery>;
export type GetRegionsLazyQueryHookResult = ReturnType<typeof useGetRegionsLazyQuery>;
export type GetRegionsQueryResult = Apollo.QueryResult<GetRegionsQuery, GetRegionsQueryVariables>;
export const GetResourceCategoriesDocument = gql`
    query getResourceCategories($pagination: PaginationArg) {
  appResources(pagination: $pagination) {
    data {
      attributes {
        category
      }
    }
  }
}
    `;
export function useGetResourceCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetResourceCategoriesQuery, GetResourceCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResourceCategoriesQuery, GetResourceCategoriesQueryVariables>(GetResourceCategoriesDocument, options);
      }
export function useGetResourceCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourceCategoriesQuery, GetResourceCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResourceCategoriesQuery, GetResourceCategoriesQueryVariables>(GetResourceCategoriesDocument, options);
        }
export type GetResourceCategoriesQueryHookResult = ReturnType<typeof useGetResourceCategoriesQuery>;
export type GetResourceCategoriesLazyQueryHookResult = ReturnType<typeof useGetResourceCategoriesLazyQuery>;
export type GetResourceCategoriesQueryResult = Apollo.QueryResult<GetResourceCategoriesQuery, GetResourceCategoriesQueryVariables>;
export const GetResourceUrlDocument = gql`
    query getResourceUrl($filter: AppResourceFiltersInput) {
  appResources(filters: $filter) {
    data {
      attributes {
        pages
        file {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetResourceUrlQuery(baseOptions?: Apollo.QueryHookOptions<GetResourceUrlQuery, GetResourceUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResourceUrlQuery, GetResourceUrlQueryVariables>(GetResourceUrlDocument, options);
      }
export function useGetResourceUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourceUrlQuery, GetResourceUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResourceUrlQuery, GetResourceUrlQueryVariables>(GetResourceUrlDocument, options);
        }
export type GetResourceUrlQueryHookResult = ReturnType<typeof useGetResourceUrlQuery>;
export type GetResourceUrlLazyQueryHookResult = ReturnType<typeof useGetResourceUrlLazyQuery>;
export type GetResourceUrlQueryResult = Apollo.QueryResult<GetResourceUrlQuery, GetResourceUrlQueryVariables>;
export const GetResourcesDocument = gql`
    query getResources($pagination: PaginationArg, $filter: AppResourceFiltersInput, $sort: [String]) {
  appResources(filters: $filter, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        createdAt
        updatedAt
        description
        category
        pages
        author {
          data {
            id
            attributes {
              name
            }
          }
        }
        file {
          data {
            id
            attributes {
              url
              size
            }
          }
        }
      }
    }
    meta {
      pagination {
        total
      }
    }
  }
}
    `;
export function useGetResourcesQuery(baseOptions?: Apollo.QueryHookOptions<GetResourcesQuery, GetResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResourcesQuery, GetResourcesQueryVariables>(GetResourcesDocument, options);
      }
export function useGetResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourcesQuery, GetResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResourcesQuery, GetResourcesQueryVariables>(GetResourcesDocument, options);
        }
export type GetResourcesQueryHookResult = ReturnType<typeof useGetResourcesQuery>;
export type GetResourcesLazyQueryHookResult = ReturnType<typeof useGetResourcesLazyQuery>;
export type GetResourcesQueryResult = Apollo.QueryResult<GetResourcesQuery, GetResourcesQueryVariables>;
export const GetRolesDocument = gql`
    query getRoles($pagination: PaginationArg, $sort: [String]) {
  usersPermissionsRoles(pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const GetSMsDocument = gql`
    query getSMs($pagination: PaginationArg, $filter: UsersPermissionsUserFiltersInput, $sort: [String]) {
  usersPermissionsUsers(pagination: $pagination, filters: $filter, sort: $sort) {
    data {
      id
      attributes {
        sales_manager {
          data {
            id
            attributes {
              name
            }
          }
        }
        dsc {
          data {
            attributes {
              sales_manager {
                data {
                  attributes {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetSMsQuery(baseOptions?: Apollo.QueryHookOptions<GetSMsQuery, GetSMsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSMsQuery, GetSMsQueryVariables>(GetSMsDocument, options);
      }
export function useGetSMsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSMsQuery, GetSMsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSMsQuery, GetSMsQueryVariables>(GetSMsDocument, options);
        }
export type GetSMsQueryHookResult = ReturnType<typeof useGetSMsQuery>;
export type GetSMsLazyQueryHookResult = ReturnType<typeof useGetSMsLazyQuery>;
export type GetSMsQueryResult = Apollo.QueryResult<GetSMsQuery, GetSMsQueryVariables>;
export const GetSalesCompetitionTypesDocument = gql`
    query getSalesCompetitionTypes {
  appSalesCompetitionTypes {
    data {
      id
      attributes {
        name
        type
      }
    }
  }
}
    `;
export function useGetSalesCompetitionTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetSalesCompetitionTypesQuery, GetSalesCompetitionTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalesCompetitionTypesQuery, GetSalesCompetitionTypesQueryVariables>(GetSalesCompetitionTypesDocument, options);
      }
export function useGetSalesCompetitionTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesCompetitionTypesQuery, GetSalesCompetitionTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalesCompetitionTypesQuery, GetSalesCompetitionTypesQueryVariables>(GetSalesCompetitionTypesDocument, options);
        }
export type GetSalesCompetitionTypesQueryHookResult = ReturnType<typeof useGetSalesCompetitionTypesQuery>;
export type GetSalesCompetitionTypesLazyQueryHookResult = ReturnType<typeof useGetSalesCompetitionTypesLazyQuery>;
export type GetSalesCompetitionTypesQueryResult = Apollo.QueryResult<GetSalesCompetitionTypesQuery, GetSalesCompetitionTypesQueryVariables>;
export const GetSalesOutDocument = gql`
    query getSalesOut($input: GetSalesOutInput!) {
  getSalesOut(input: $input) {
    total
    chart {
      january {
        haircare
        color_me
      }
      february {
        haircare
        color_me
      }
      march {
        haircare
        color_me
      }
      april {
        haircare
        color_me
      }
      may {
        haircare
        color_me
      }
      june {
        haircare
        color_me
      }
      july {
        haircare
        color_me
      }
      august {
        haircare
        color_me
      }
      september {
        haircare
        color_me
      }
      october {
        haircare
        color_me
      }
      november {
        haircare
        color_me
      }
      december {
        haircare
        color_me
      }
    }
  }
}
    `;
export function useGetSalesOutQuery(baseOptions: Apollo.QueryHookOptions<GetSalesOutQuery, GetSalesOutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalesOutQuery, GetSalesOutQueryVariables>(GetSalesOutDocument, options);
      }
export function useGetSalesOutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesOutQuery, GetSalesOutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalesOutQuery, GetSalesOutQueryVariables>(GetSalesOutDocument, options);
        }
export type GetSalesOutQueryHookResult = ReturnType<typeof useGetSalesOutQuery>;
export type GetSalesOutLazyQueryHookResult = ReturnType<typeof useGetSalesOutLazyQuery>;
export type GetSalesOutQueryResult = Apollo.QueryResult<GetSalesOutQuery, GetSalesOutQueryVariables>;
export const GetSalonDocument = gql`
    query getSalon($id: ID!) {
  salon(id: $id) {
    data {
      id
      attributes {
        name
        isActive
        salonSize
        salonType
        salonAge
        country
        insiderPermission
        totalStylists
        city
        state
        region {
          data {
            id
            attributes {
              name
            }
          }
        }
        distributor {
          data {
            id
            attributes {
              name
            }
          }
        }
        dsc {
          data {
            id
            attributes {
              name
            }
          }
        }
        sales_manager {
          data {
            id
            attributes {
              name
            }
          }
        }
        owner {
          data {
            id
            attributes {
              blocked
              isAppActivated
              email
              appVersion
              username
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetSalonQuery(baseOptions: Apollo.QueryHookOptions<GetSalonQuery, GetSalonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalonQuery, GetSalonQueryVariables>(GetSalonDocument, options);
      }
export function useGetSalonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalonQuery, GetSalonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalonQuery, GetSalonQueryVariables>(GetSalonDocument, options);
        }
export type GetSalonQueryHookResult = ReturnType<typeof useGetSalonQuery>;
export type GetSalonLazyQueryHookResult = ReturnType<typeof useGetSalonLazyQuery>;
export type GetSalonQueryResult = Apollo.QueryResult<GetSalonQuery, GetSalonQueryVariables>;
export const GetSalonDistributorNamesDocument = gql`
    query getSalonDistributorNames($filter: SalonFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  salons(filters: $filter, pagination: $pagination, sort: $sort) {
    data {
      attributes {
        distributor {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetSalonDistributorNamesQuery(baseOptions?: Apollo.QueryHookOptions<GetSalonDistributorNamesQuery, GetSalonDistributorNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalonDistributorNamesQuery, GetSalonDistributorNamesQueryVariables>(GetSalonDistributorNamesDocument, options);
      }
export function useGetSalonDistributorNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalonDistributorNamesQuery, GetSalonDistributorNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalonDistributorNamesQuery, GetSalonDistributorNamesQueryVariables>(GetSalonDistributorNamesDocument, options);
        }
export type GetSalonDistributorNamesQueryHookResult = ReturnType<typeof useGetSalonDistributorNamesQuery>;
export type GetSalonDistributorNamesLazyQueryHookResult = ReturnType<typeof useGetSalonDistributorNamesLazyQuery>;
export type GetSalonDistributorNamesQueryResult = Apollo.QueryResult<GetSalonDistributorNamesQuery, GetSalonDistributorNamesQueryVariables>;
export const GetSalonEmailsDocument = gql`
    query getSalonEmails($filter: SalonFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  salons(filters: $filter, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        owner {
          data {
            attributes {
              email
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetSalonEmailsQuery(baseOptions?: Apollo.QueryHookOptions<GetSalonEmailsQuery, GetSalonEmailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalonEmailsQuery, GetSalonEmailsQueryVariables>(GetSalonEmailsDocument, options);
      }
export function useGetSalonEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalonEmailsQuery, GetSalonEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalonEmailsQuery, GetSalonEmailsQueryVariables>(GetSalonEmailsDocument, options);
        }
export type GetSalonEmailsQueryHookResult = ReturnType<typeof useGetSalonEmailsQuery>;
export type GetSalonEmailsLazyQueryHookResult = ReturnType<typeof useGetSalonEmailsLazyQuery>;
export type GetSalonEmailsQueryResult = Apollo.QueryResult<GetSalonEmailsQuery, GetSalonEmailsQueryVariables>;
export const GetSalonNamesDocument = gql`
    query getSalonNames($filter: SalonFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  salons(filters: $filter, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;
export function useGetSalonNamesQuery(baseOptions?: Apollo.QueryHookOptions<GetSalonNamesQuery, GetSalonNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalonNamesQuery, GetSalonNamesQueryVariables>(GetSalonNamesDocument, options);
      }
export function useGetSalonNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalonNamesQuery, GetSalonNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalonNamesQuery, GetSalonNamesQueryVariables>(GetSalonNamesDocument, options);
        }
export type GetSalonNamesQueryHookResult = ReturnType<typeof useGetSalonNamesQuery>;
export type GetSalonNamesLazyQueryHookResult = ReturnType<typeof useGetSalonNamesLazyQuery>;
export type GetSalonNamesQueryResult = Apollo.QueryResult<GetSalonNamesQuery, GetSalonNamesQueryVariables>;
export const GetSalonSMnamesDocument = gql`
    query getSalonSMnames($filter: SalonFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  salons(filters: $filter, pagination: $pagination, sort: $sort) {
    data {
      attributes {
        sales_manager {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetSalonSMnamesQuery(baseOptions?: Apollo.QueryHookOptions<GetSalonSMnamesQuery, GetSalonSMnamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalonSMnamesQuery, GetSalonSMnamesQueryVariables>(GetSalonSMnamesDocument, options);
      }
export function useGetSalonSMnamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalonSMnamesQuery, GetSalonSMnamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalonSMnamesQuery, GetSalonSMnamesQueryVariables>(GetSalonSMnamesDocument, options);
        }
export type GetSalonSMnamesQueryHookResult = ReturnType<typeof useGetSalonSMnamesQuery>;
export type GetSalonSMnamesLazyQueryHookResult = ReturnType<typeof useGetSalonSMnamesLazyQuery>;
export type GetSalonSMnamesQueryResult = Apollo.QueryResult<GetSalonSMnamesQuery, GetSalonSMnamesQueryVariables>;
export const GetSalonsDocument = gql`
    query getSalons($filter: SalonFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  salons(filters: $filter, pagination: $pagination, sort: $sort) {
    meta {
      pagination {
        total
      }
    }
    data {
      id
      attributes {
        name
        state
        region {
          data {
            id
            attributes {
              name
            }
          }
        }
        distributor {
          data {
            id
            attributes {
              name
            }
          }
        }
        dsc {
          data {
            id
            attributes {
              name
            }
          }
        }
        sales_manager {
          data {
            id
            attributes {
              name
            }
          }
        }
        owner {
          data {
            id
            attributes {
              blocked
              isAppActivated
              email
              username
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetSalonsQuery(baseOptions?: Apollo.QueryHookOptions<GetSalonsQuery, GetSalonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalonsQuery, GetSalonsQueryVariables>(GetSalonsDocument, options);
      }
export function useGetSalonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalonsQuery, GetSalonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalonsQuery, GetSalonsQueryVariables>(GetSalonsDocument, options);
        }
export type GetSalonsQueryHookResult = ReturnType<typeof useGetSalonsQuery>;
export type GetSalonsLazyQueryHookResult = ReturnType<typeof useGetSalonsLazyQuery>;
export type GetSalonsQueryResult = Apollo.QueryResult<GetSalonsQuery, GetSalonsQueryVariables>;
export const GetSecondLeaderboardDocument = gql`
    query getSecondLeaderboard($input: AppGetCompetitionLeaderboardInput!) {
  appGetCompetitionLeaderboard(input: $input) {
    participants
    leaderboard {
      quantity
      deals
      name
      country
      distributor
    }
  }
}
    `;
export function useGetSecondLeaderboardQuery(baseOptions: Apollo.QueryHookOptions<GetSecondLeaderboardQuery, GetSecondLeaderboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSecondLeaderboardQuery, GetSecondLeaderboardQueryVariables>(GetSecondLeaderboardDocument, options);
      }
export function useGetSecondLeaderboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSecondLeaderboardQuery, GetSecondLeaderboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSecondLeaderboardQuery, GetSecondLeaderboardQueryVariables>(GetSecondLeaderboardDocument, options);
        }
export type GetSecondLeaderboardQueryHookResult = ReturnType<typeof useGetSecondLeaderboardQuery>;
export type GetSecondLeaderboardLazyQueryHookResult = ReturnType<typeof useGetSecondLeaderboardLazyQuery>;
export type GetSecondLeaderboardQueryResult = Apollo.QueryResult<GetSecondLeaderboardQuery, GetSecondLeaderboardQueryVariables>;
export const GetTeamFiltersDocument = gql`
    query GetTeamFilters($input: TeamFiltersInput!) {
  getTeamFilters(input: $input) {
    filters {
      name
      email
      smName
      dName
      active
      inactive
      blocked
      region
      insiderPermission
    }
  }
}
    `;
export function useGetTeamFiltersQuery(baseOptions: Apollo.QueryHookOptions<GetTeamFiltersQuery, GetTeamFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamFiltersQuery, GetTeamFiltersQueryVariables>(GetTeamFiltersDocument, options);
      }
export function useGetTeamFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamFiltersQuery, GetTeamFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamFiltersQuery, GetTeamFiltersQueryVariables>(GetTeamFiltersDocument, options);
        }
export type GetTeamFiltersQueryHookResult = ReturnType<typeof useGetTeamFiltersQuery>;
export type GetTeamFiltersLazyQueryHookResult = ReturnType<typeof useGetTeamFiltersLazyQuery>;
export type GetTeamFiltersQueryResult = Apollo.QueryResult<GetTeamFiltersQuery, GetTeamFiltersQueryVariables>;
export const GetTermsDocument = gql`
    query getTerms {
  appTermCondition {
    data {
      attributes {
        content
      }
    }
  }
}
    `;
export function useGetTermsQuery(baseOptions?: Apollo.QueryHookOptions<GetTermsQuery, GetTermsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTermsQuery, GetTermsQueryVariables>(GetTermsDocument, options);
      }
export function useGetTermsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTermsQuery, GetTermsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTermsQuery, GetTermsQueryVariables>(GetTermsDocument, options);
        }
export type GetTermsQueryHookResult = ReturnType<typeof useGetTermsQuery>;
export type GetTermsLazyQueryHookResult = ReturnType<typeof useGetTermsLazyQuery>;
export type GetTermsQueryResult = Apollo.QueryResult<GetTermsQuery, GetTermsQueryVariables>;
export const GetUserEmailsDocument = gql`
    query getUserEmails($filter: UsersPermissionsUserFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  usersPermissionsUsers(filters: $filter, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        email
      }
    }
  }
}
    `;
export function useGetUserEmailsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserEmailsQuery, GetUserEmailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserEmailsQuery, GetUserEmailsQueryVariables>(GetUserEmailsDocument, options);
      }
export function useGetUserEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserEmailsQuery, GetUserEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserEmailsQuery, GetUserEmailsQueryVariables>(GetUserEmailsDocument, options);
        }
export type GetUserEmailsQueryHookResult = ReturnType<typeof useGetUserEmailsQuery>;
export type GetUserEmailsLazyQueryHookResult = ReturnType<typeof useGetUserEmailsLazyQuery>;
export type GetUserEmailsQueryResult = Apollo.QueryResult<GetUserEmailsQuery, GetUserEmailsQueryVariables>;
export const GetUserNamesDocument = gql`
    query getUserNames($filter: UsersPermissionsUserFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  usersPermissionsUsers(filters: $filter, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        name
        email
        blocked
        distributor {
          data {
            id
            attributes {
              name
            }
          }
        }
        sales_manager {
          data {
            id
          }
        }
        dsc {
          data {
            id
          }
        }
      }
    }
  }
}
    `;
export function useGetUserNamesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserNamesQuery, GetUserNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserNamesQuery, GetUserNamesQueryVariables>(GetUserNamesDocument, options);
      }
export function useGetUserNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserNamesQuery, GetUserNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserNamesQuery, GetUserNamesQueryVariables>(GetUserNamesDocument, options);
        }
export type GetUserNamesQueryHookResult = ReturnType<typeof useGetUserNamesQuery>;
export type GetUserNamesLazyQueryHookResult = ReturnType<typeof useGetUserNamesLazyQuery>;
export type GetUserNamesQueryResult = Apollo.QueryResult<GetUserNamesQuery, GetUserNamesQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers($filter: UsersPermissionsUserFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  usersPermissionsUsers(filters: $filter, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        name
        email
        isAppActivated
        createdAt
        insiderPermission
        updatedAt
        phoneNumber
        blocked
        appVersion
        role {
          data {
            id
            attributes {
              name
            }
          }
        }
        avatar {
          data {
            attributes {
              url
            }
          }
        }
        guest {
          data {
            id
            attributes {
              name
              guest_type
              region {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
            }
          }
        }
        dsc {
          data {
            id
            attributes {
              ranking
              name
              last_activity
              log_count
              sales_manager {
                data {
                  id
                  attributes {
                    name
                    distributor {
                      data {
                        id
                        attributes {
                          name
                          region {
                            data {
                              id
                              attributes {
                                name
                              }
                            }
                          }
                        }
                      }
                    }
                    region {
                      data {
                        id
                        attributes {
                          name
                        }
                      }
                    }
                  }
                }
              }
              distributor {
                data {
                  id
                  attributes {
                    name
                    region {
                      data {
                        id
                        attributes {
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        sales_manager {
          data {
            id
            attributes {
              last_activity
              log_count
              name
              distributor {
                data {
                  id
                  attributes {
                    name
                    region {
                      data {
                        id
                        attributes {
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        distributor {
          data {
            id
            attributes {
              logs
              name
              region {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
            }
          }
        }
        region {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
    meta {
      pagination {
        total
      }
    }
  }
}
    `;
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    name
    email
    avatar {
      data {
        id
        attributes {
          url
        }
      }
    }
    region {
      data {
        id
        attributes {
          name
        }
      }
    }
    active
    username
    appVersion
    salon {
      data {
        id
        attributes {
          isActive
          name
          avatar {
            data {
              id
              attributes {
                name
                url
              }
            }
          }
        }
      }
    }
    sales_manager {
      data {
        id
        attributes {
          name
        }
      }
    }
    dsc {
      data {
        id
        attributes {
          name
        }
      }
    }
    distributor {
      data {
        id
        attributes {
          name
        }
      }
    }
    role {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
}
    `;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const TranslateDocument = gql`
    query translate($input: TranslateInput!) {
  translate(input: $input) {
    translations {
      __typename
      text
      detectedSourceLang
      targetLanguage
    }
    success
  }
}
    `;
export function useTranslateQuery(baseOptions: Apollo.QueryHookOptions<TranslateQuery, TranslateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TranslateQuery, TranslateQueryVariables>(TranslateDocument, options);
      }
export function useTranslateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TranslateQuery, TranslateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TranslateQuery, TranslateQueryVariables>(TranslateDocument, options);
        }
export type TranslateQueryHookResult = ReturnType<typeof useTranslateQuery>;
export type TranslateLazyQueryHookResult = ReturnType<typeof useTranslateLazyQuery>;
export type TranslateQueryResult = Apollo.QueryResult<TranslateQuery, TranslateQueryVariables>;