import { areAllKeysPopulated, areObjectsEqual, removeSelectDuplicates, truncate } from "../../../../../../../utils"
import { ReactComponent as DangerInfoIcon } from "../../../../../../../icons/danger-info.svg"
import { Form as AntForm, ConfigProvider, Empty, Input, Modal, Select, Spin } from "antd"
import { ReactComponent as SearchIcon } from "../../../../../../../icons/search.svg"
import { ReactComponent as UnblockIcon } from "../../../../../../../icons/unblock.svg"
import { ReactComponent as BlockIcon } from "../../../../../../../icons/block.svg"
import { ReactComponent as TrashIcon } from "../../../../../../../icons/trash.svg"
import { ReactComponent as SaveIcon } from "../../../../../../../icons/check.svg"
import ToolTip from "../../../../../components/ToolTip"
import React, { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import "./index.less"
import {
  useCreateDistributorEntityMutation,
  useUpdateDistributorEntityMutation,
  useGetDistributorEntitiesLazyQuery,
  useCreateOrUpdateUserMutation,
  useDeleteUserMutation,
  useGetRegionsLazyQuery,
  useGetSMsLazyQuery, useBlockingUserMutation,
} from "../../../../../../../graphql"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import { useAuth } from "../../../../../../../components/auth"

const pagination = { limit: 10000, start: 0 }

const LeftSide: FC<TeamManageBlockLeftSideInterface> = ({
  usersAssignedToDistributor,
  setInitialFilters,
  initialFilters,
  setFilters,
  setBlocked,
  blocked,
  userId,
  filters,
  role
}) => {
  const navigate = useNavigate()
  const { permissions, region } = useAuth()
  const blockPermissions = permissions?.firstMenu
    ?.find(item => item.name == "Team")
    ?.blocks?.find(
      (block: any) => block.name == (filters?.role === "Distributor Entity" ? "Distributor" : filters?.role)
    )

  const [createDistributor] = useCreateDistributorEntityMutation()
  const [updateDistributor] = useUpdateDistributorEntityMutation()
  const [deleteUser] = useDeleteUserMutation()
  const [blockingUser] = useBlockingUserMutation()

  const [getRegionsQuery] = useGetRegionsLazyQuery()
  const [getDistributorEntitiesQuery] = useGetDistributorEntitiesLazyQuery()
  const [createOrUpdateUser] = useCreateOrUpdateUserMutation()
  const [getSMsQuery] = useGetSMsLazyQuery()

  const [finishLoading, setFinishLoading] = useState<boolean>(false)
  const [modalLoading, setModalLoading] = useState<boolean>(false)
  const [isFormReady, setIsFormReady] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const roleVariants = ["DSC", "Sales Manager", "Distributor", "Region"]
  const roleOptions = Array.from({ length: 4 }, (_, i) => ({
    label: roleVariants[i], value: roleVariants[i], key: String(i),
  }))

  const [options, setOptions] = useState<{ [key: string]: SelectItems | null }>({
    regions: null,
    distributors: null,
    sales_managers: null,
    roles: roleOptions
  })

  const filteredOption = (input: string, option: { label: string; value: string; key?: number }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleModalOk = async () => {
    setModalLoading(true)
    try {
      let response: any
      let isRequestSuccessful: boolean;

      if (filters?.role == "Distributor Entity") {
        response = await deleteUser({
          variables: {
            input: {
              user_id: userId,
              isDistributor: filters?.role == "Distributor Entity",
            },
          },
        })
        isRequestSuccessful = response?.data?.deleteUser?.success

      } else {
        response = await blockingUser({
          variables: {
            input: {
              blocked: !blocked,
              user_id: userId,
            }
          }
        })
        isRequestSuccessful = response?.data?.blockingUser?.success
      }

      if (isRequestSuccessful) {
        setModalLoading(false)
        setIsModalOpen(false)
      }

      if (isRequestSuccessful && filters?.role !== "Distributor Entity") {
        setBlocked(response?.data?.blockingUser?.blocked)
      } else {

        setTimeout(() => {
          navigate("/account/team/distributor/entities")
        }, 500)
      }

    } catch (error) {
      setModalLoading(false)
    }
  }

  const handleModalCancel = () => {
    setIsModalOpen(false)
  }

  const getItems = async (type: string) => {
    const itemCreator = (item: any) =>
      type !== "region" && type !== "distributor"
        ? {
          value: item.attributes[type]?.data?.attributes?.name!,
          label: item.attributes[type]?.data?.attributes?.name!,
          key: item.attributes[type]?.data?.id!,
        }
        : {
          value: item.attributes?.name!,
          label: item.attributes?.name!,
          key: item?.id!,
        }

    const createVariables = (role: string) => ({
      variables: {
        filter: {
          ...(region?.id ? { sales_manager: { distributor: { region: { id: { eq: region.id } } } } } : {}),
          role: { name: { eq: role } }
        },
        pagination,
      },
    })

    switch (type) {
      case "sales_manager":
        setLoading(true)
        const SalesManagersData = await getSMsQuery(createVariables("Sales Manager"))
        const SalesManagersOptions = SalesManagersData?.data?.usersPermissionsUsers?.data
          .map(item => itemCreator(item))
          .filter(item => item.value)
        setOptions({
          ...options,
          sales_managers: removeSelectDuplicates(SalesManagersOptions!),
        })
        setLoading(false)
        break

      case "distributor":
        setLoading(true)
        const DistributorsData = await getDistributorEntitiesQuery({
          variables: {
            ...(region?.id ? { filter: { region: { id: { eq: region.id } } } } : {}),
            pagination,
          },
          fetchPolicy: "no-cache"
        })
        const distributorsOptions = DistributorsData?.data?.distributors?.data
          .map(item => itemCreator(item))
          .filter(item => item.value)
        setOptions({
          ...options,
          distributors: distributorsOptions!,
        })
        setLoading(false)
        break

      case "region":
        setLoading(true)
        const regionsData = await getRegionsQuery()
        let regionsOptions = regionsData?.data?.regions?.data
          .map(item => itemCreator(item))
          .filter(item => item.value)
        if (region?.id) {
          regionsOptions = regionsOptions?.filter(item => item.key == region.id)
        }

        setOptions({
          ...options,
          regions: removeSelectDuplicates(regionsOptions!),
        })
        setLoading(false)
        break
    }
  }

  const handleValuesChange = (value: string | boolean, type: string) => {
    setErrorMessage("")
    setFilters({
      ...filters,
      [type]: value,
    })
  }

  const handleFinish = async () => {
    setFinishLoading(true)
    let { region, distributor, sales_manager, name, email, status, phone, insiderPermission = false } = filters as any

    email = email?.toLowerCase()
    try {
      let type
      let response: any
      if (filters?.role !== "Distributor Entity") {
        type = "createOrUpdateUser"
      } else if (userId) {
        type = "updateDistributor"
      } else {
        type = "createDistributor"
      }

      switch (type) {
        case "createOrUpdateUser":
          response = await createOrUpdateUser({
            variables: {
              input: {
                ...(userId ? { user_id: userId } : {}),
                data: {
                  role: filters?.role,
                  email,
                  name,
                  insiderPermission: insiderPermission || false,
                  isAppActivated: status == "Active",
                  ...(filters?.role == "Region" ? { region: options.regions?.find(item => item.value == region)!.key } : {}),
                  ...(filters?.role == "Distributor" || filters?.role == "Sales Manager"
                    ? { distributor: options.distributors?.find(item => item.value == distributor)!.key }
                    : {}),
                  ...(filters?.role == "DSC"
                    ? { sales_manager: options.sales_managers?.find(item => item.value == sales_manager)!.key }
                    : {}),
                  ...(filters!.phone ? { phoneNumber: phone } : {}),
                },
              },
            },
          })
          break
        case "updateDistributor":
          response = await updateDistributor({
            variables: {
              id: String(userId),
              input: {
                name,
                region: options.regions?.find(item => item.value == region)!.key,
              },
            },
          })
          break
        case "createDistributor":
          response = await createDistributor({
            variables: {
              input: {
                name,
                region: options.regions?.find(item => item.value == region)!.key,
              },
            },
          })
          break
      }

      if (response?.data) {
        if (role !== filters?.role) {
          switch (filters?.role) {
            case "DSC":
              navigate(`/account/team/dsc/${userId}`)
              break
            case "Sales Manager":
              navigate(`/account/team/sales-manager/${userId}`)
              break
            case "Distributor":
              navigate(`/account/team/distributor/users/${userId}`)
              break
            case "Region":
              navigate(`/account/team/region/${userId}`)
              break
          }
        }
        setInitialFilters(filters)
      }
      setFinishLoading(false)
    } catch (error: any) {
      setFinishLoading(false)
      error!.message && setErrorMessage(truncate(error.message, 100))
    }
  }

  const createSelectElement = (
    type: string,
    name: string,
    loading: boolean,
    options: SelectItems,
    isDisabled = false,
  ) => {
    let value = filters?.[type] || ""
    if (type == "status" && blocked) {
      value = "Blocked"
    }

    return (
      <AntForm.Item name={name} label={name} required={true}>
        <div className={"input-wrapper"}>
          <Select
            showSearch
            value={value}
            optionFilterProp='children'
            onChange={data => handleValuesChange(data, type)}
            filterOption={filteredOption as any}
            options={options}
            defaultActiveFirstOption={true}
            disabled={isDisabled}
            notFoundContent={
              loading || !options ? (
                <div className={"no-data"} children={<Spin />} />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={"no-data"} />
              )
            }
            suffixIcon={<SearchIcon />}
          />
        </div>
      </AntForm.Item>
    )
  }

  const createInputElement = (type: string, label: string) => (
    <AntForm.Item label={label} name='name' required={type !== "phone"}>
      <div className={"input-wrapper"}>
        <Input
          placeholder={"Type here"}
          onChange={e => handleValuesChange(e.target.value, type)}
          value={filters?.[type] || ""}
          disabled={type === "email" && !!initialFilters?.email}
        />
      </div>
    </AntForm.Item>
  )

  useEffect(() => {
    if (filters && initialFilters) {
      const notRequiredKeys = ["phone", "insiderPermission"]
      if (filters?.role == "DSC") {
        notRequiredKeys.push(...["region", "distributor"])
      }
      if (filters?.role == "Sales Manager" || filters?.role == "Distributor") {
        notRequiredKeys.push(...["region", "sales_manager"])
      }
      if (filters?.role == "Region") {
        notRequiredKeys.push(...["distributor", "sales_manager",])
      }
      if (filters?.role == "Distributor Entity") {
        notRequiredKeys.push(...["distributor", "sales_manager", "role", "email", "status"])
      }
      setIsFormReady(!areObjectsEqual(filters, initialFilters) && areAllKeysPopulated(filters, notRequiredKeys))
    }
  }, [filters, initialFilters])

  useEffect(() => {
    let relation
    if (filters?.role == "DSC") {
      relation = "sales_manager"
    }
    if (filters?.role == "Sales Manager" || filters?.role == "Distributor") {
      relation = "distributor"
    }
    if (filters?.role == "Region" || filters?.role == "Distributor Entity") {
      relation = "region"
    }
    filters?.role && getItems(relation!)
  }, [filters?.role])

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              colorBorder: "#ffffff",
              colorPrimaryHover: "#ffffff",
              controlOutline: "#ffffff",
              colorError: "#ffffff",
              colorErrorBorderHover: "#ffffff",
              colorErrorOutline: "#ffffff",
              colorIcon: "#1C1C23",
              borderRadius: 0,
            },
            Select: {
              colorText: "#000",
              colorPrimaryBorder: "#fff",
              colorPrimaryHover: "#fff",
              controlOutline: "#fff",
              colorBgContainerDisabled: "#fff",
              colorBorder: "#fff",
            },
            Button: {
              borderRadius: 0,
              colorBorder: "#000",
              colorPrimary: "#000",
              colorPrimaryHover: "#707070",
              colorText: "#000",
            },
            Modal: {
              borderRadiusLG: 0,
            },
          },
        }}
      >
        <AntForm layout='vertical'>
          <div className={"info-wrapper team"}>
            <div className={"block"}>
              <div className={"title"}>GENERAL INFORMATION</div>
              <div className={"inputs-wrapper"}>
                {createInputElement("name", filters?.role == "Distributor Entity" ? "Name" : "Full Name")}
                {filters?.role == "Distributor Entity"
                  ? createSelectElement("region", "Region", loading, options.regions!, false)
                  : createInputElement("email", "Email")}
              </div>
            </div>
            {filters?.role !== "Distributor Entity" && (
              <>
                <div className={"block"}>
                  <div className={"title"}>
                    <div>ROLE</div>
                    <div>APP STATUS</div>
                  </div>
                  <div className={"inputs-wrapper"}>
                    {createSelectElement("role", "Role", loading, roleOptions)}
                    {createSelectElement(
                      "status",
                      "App Status",
                      loading,
                      [
                        { label: "Active", value: "Active", key: "1" },
                        { label: "Inactive", value: "Inactive", key: "2" },
                      ],
                      true
                    )}
                  </div>
                  {filters?.role === "Region" && <div className={"switch-holder"}>
                    <label className={"switch"}>
                      <input
                        disabled={blocked}
                        type={"checkbox"}
                        checked={!!filters?.insiderPermission}
                        onChange={e => handleValuesChange(e.target.checked, "insiderPermission")}
                      />
                      <span className={"slider round"} />
                    </label>
                    Allow this Region to enter KM INSIDER
                  </div>}
                </div>

                <div className={"block"}>
                  <div className={"title"}>
                    <div>RELATIONS</div>
                    <div>PHONE</div>
                  </div>
                  <div className={"inputs-wrapper"}>
                    {filters?.role == "DSC" &&
                      createSelectElement("sales_manager", "Sales Manager", loading, options.sales_managers!)}
                    {(filters?.role == "Distributor" || filters?.role == "Sales Manager") &&
                      createSelectElement("distributor", "Distributor", loading, options.distributors!)}
                    {filters?.role == "Region" && createSelectElement("region", "Region", loading, options.regions!)}
                    {createInputElement("phone", "Phone Number")}
                  </div>

                </div>
              </>
            )}
            {errorMessage && <div className={"error"}>{errorMessage}</div>}
          </div>
        </AntForm>
        <Modal open={isModalOpen} onOk={handleModalOk} onCancel={handleModalCancel} className={"team"}>
          <div className={"modal-content-wrapper"}>
            <DangerInfoIcon />
            <p>{`Are you sure you want to ${filters?.role != "Distributor Entity" ? (!blocked ? "block" : "unblock") : "delete"} an existing ${filters?.role !== "Distributor Entity" ? "user" : "distributor"
              }?`}</p>
            <div className={"loading"}>{modalLoading && <Spin />}</div>
          </div>
        </Modal>
      </ConfigProvider>

      {blockPermissions?.edit && (
        <button
          type={"submit"}
          onClick={handleFinish}
          className={`save ${isFormReady && !(initialFilters?.name && !userId) && "ready"}`}
          disabled={!isFormReady || !!(initialFilters?.name && !userId)}
        >
          Save
          {finishLoading ? <Spin /> : <SaveIcon />}
        </button>
      )}

      <SwitchTransition mode={"out-in"}>
        <CSSTransition
          key={JSON.stringify(blocked)}
          timeout={250}
          classNames={"fade"}
          appear={true}
          unmountOnExit
        >
          <>
            {userId && blockPermissions?.delete ? (
              <button
                onClick={showModal}
                disabled={!!usersAssignedToDistributor}
                className={`${!blocked ? "block-user" : "unblock-user"} ${(filters?.role == "Distributor Entity" && usersAssignedToDistributor) ? "disabled" : ""}`}>
                <ToolTip title={`Currently, there ${usersAssignedToDistributor! > 1 ? "are" : "is"} ${usersAssignedToDistributor} user${usersAssignedToDistributor! > 1 ? "s" : ""} associated with this distributor`} disabled={!usersAssignedToDistributor}>
                  {filters?.role == "Distributor Entity" ? "Delete Distributor" : !blocked ? "Block User" : "Unblock User"}
                  {filters?.role == "Distributor Entity" ? <TrashIcon /> : !blocked ? <BlockIcon /> : <UnblockIcon />}
                </ToolTip>
              </button>
            ) : null}
          </>
        </CSSTransition>
      </SwitchTransition>
    </>
  )
}

export default LeftSide
