import { areAllKeysPopulated, areObjectsEqual, checkFormNotDone, deepEqualArrays, setNewTranslationText, truncate } from "../../../../../../../../../utils"
import { ReactComponent as CalendarIcon } from "../../../../../../../../../icons/calendar.svg"
import { ReactComponent as SearchIcon } from "../../../../../../../../../icons/search.svg"
import { ReactComponent as ClockIcon } from "../../../../../../../../../icons/clock.svg"
import { ReactComponent as CloseIcon } from "../../../../../../../../../icons/close.svg"
import { ReactComponent as TrashIcon } from "../../../../../../../../../icons/trash.svg"
import { ReactComponent as SaveIcon } from "../../../../../../../../../icons/check.svg"
import { TextEditor } from "../../../../../../../components/TextEditor"
import { useAuth } from "../../../../../../../../../components/auth"
import { DownOutlined } from "@ant-design/icons"
import { Form as AntForm, Dropdown, Empty, Input, Select, Space, Spin } from "antd"
import React, { FC, useCallback, useEffect, useRef, useState } from "react"
import { DateTime } from "luxon"
import "./index.less"
import {
  useGetDistributorEntitiesLazyQuery,
  useCreatePostMutation,
  useGetRegionsLazyQuery,
  useUpdatePostMutation,
  useTranslateLazyQuery,
  useDeleteTranslationsMutation,
} from "../../../../../../../../../graphql"
import { CustomCalendar } from "../../../../../../../components/CustomCalendar"
import TransatableEditableContentWrapper from "../../../../../../../../Account/components/TranslatableEditableWrapper"
import { generateItems, Languages } from "../../../../../../../../../utils/variables/variables"
import { createPortal } from "react-dom"
import TranslationModal from "../../../../../../../components/TranslationModal"

const url = import.meta.env.WEBSITE_API_URL?.replace("/graphql", "") || ""
const pagination = { limit: 10000, start: 0 }

const Form: FC<PostManageBlockLeftSideFormInterface> = ({
  post,
  options,
  filters,
  showModal,
  setOptions,
  setFilters,
  initialFilters,
  selectedPostId,
  isChangesDisabled,
  setInitialFilters,
}) => {
  const { permissions, userId, region } = useAuth()
  const blockPermissions = permissions?.firstMenu?.find(item => item.name == "Content")?.blocks?.find((block: any) => block.name == "Posts")

  const [createPost] = useCreatePostMutation()
  const [updatePost] = useUpdatePostMutation()
  const [getDistributorEntitiesQuery] = useGetDistributorEntitiesLazyQuery()
  const [getRegionsQuery] = useGetRegionsLazyQuery()
  const [translate] = useTranslateLazyQuery()
  const [deleteTranslations] = useDeleteTranslationsMutation()

  const calendarSelectElement = useRef<any>(null)

  const [loading, setLoading] = useState<boolean>(false)
  const [finishLoading, setFinishLoading] = useState<boolean>(false)
  const [isFormReady, setIsFormReady] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [selectedDescriptionLanguage, setSelectedDescriptionLanguage] = useState<{ title: string, value: string }>({ title: Languages.EN_FULL_TITLE, value: Languages.EN })
  const [isTranslationModalOpen, setIsTranslationModalOpen] = useState<boolean>(false)
  const [translationsVariants, setTranslationVariants] = useState<any[]>([])
  const [intitalVariants, setIntitalVariants] = useState<any[]>([])
  const [isTranslationLoading, setIsTranslationLoading] = useState<boolean>(false)
  const [isAutoTranslateEnabled, setAutoTranslateEnabled] = useState<boolean>(true)
  const [confirmedTranslationDisable, setConfirmedTranslationDisable] = useState<boolean>(false)

  const toggleSwitcher = () => {
    if (!confirmedTranslationDisable) {
      setConfirmedTranslationDisable(true)
      setIsTranslationModalOpen(true)
      setAutoTranslateEnabled(!isAutoTranslateEnabled)
    }
    setTranslationVariants([])
    setAutoTranslateEnabled(!isAutoTranslateEnabled)
  }

  useEffect(() => {
    const existingVariants = post?.attributes?.localizations?.data?.map(({ id, attributes }: { attributes: any, id: string }) => {
      return { targetLanguage: attributes.locale, text: attributes.body, detectedSourceLang: "en", id }
    })
    setTranslationVariants(existingVariants || [])
    setIntitalVariants(existingVariants || [])
  }, [])

  const formatTranslates = useCallback((translates: any[]) => {
    if (translates.length === 0) {
      return []
    }
    return translates.map(e => { return { id: e?.id, detectedSourceLang: e?.detectedSourceLang, text: e?.text, targetLanguage: e?.targetLanguage } })
  }, [])

  const handleTranslation = async ({ text }: { text: string }) => {
    setIsTranslationLoading(true)
    try {
      const { data: translation } = await translate({
        variables: {
          input: {
            langs: [Languages.FR, Languages.DE, Languages.ES],
            text,
            onlyTranslationMode: true,
          }
        }
      })

      if (translation && translationsVariants[0]?.id) {
        const updatedVariants = translationsVariants.map((v) => { return { ...v, text: setNewTranslationText({ newTranslations: translation.translate?.translations, lang: v.targetLanguage }) } })

        setTranslationVariants(formatTranslates(updatedVariants) || [])
        setIsTranslationLoading(false)
        return
      }
      setTranslationVariants(formatTranslates(translation?.translate?.translations || []) || [])
      setIsTranslationLoading(false)
    } catch (error: any) {
      setIsTranslationLoading(false)
      setErrorMessage(truncate(error.message, 100))
    }
  }

  const filteredOption = (input: string, option: { label: string; value: string; key?: number }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  const generateTimeOptions = (): SelectItems =>
    Array.from({ length: 96 }, (_, index) => {
      const hours = Math.floor((index * 15) / 60)
      const minutes = (index * 15) % 60
      const formattedHours = hours.toString().padStart(2, "0")
      const formattedMinutes = minutes.toString().padStart(2, "0")
      const time = `${formattedHours}:${formattedMinutes}`

      return {
        value: time,
        label: time,
        key: String(index),
      }
    })

  const generateTimeZoneOptions = (): SelectItems => {
    const utcOffsets = Array.from({ length: 27 }, (_, index) => index - 12)
    return utcOffsets.map(offset => {
      const formattedOffset = offset >= 0 ? `+${offset}` : `${offset}`

      return {
        value: `UTC${formattedOffset}`,
        label: `UTC${formattedOffset}`,
        key: `UTC${formattedOffset}`,
      }
    })
  }

  const getItems = async () => {
    setLoading(true)

    const itemCreator = (item: any, type: string) =>
      type !== "region" && type !== "distributor"
        ? {
          value: item.attributes[type].data.attributes.name,
          label: item.attributes[type].data.attributes.name,
          key: item.attributes[type].data.id,
        }
        : {
          value: item.attributes.name,
          label: item.attributes.name,
          key: item.id,
        }

    const distributorsData = await getDistributorEntitiesQuery({
      variables: {
        pagination,
        ...(region?.id ? { filter: { region: { id: { eq: region.id } } } } : {}),
        sort: "name:asc"
      }
    })
    const distributorsOptions = distributorsData?.data?.distributors?.data
      .map(item => itemCreator(item, "distributor"))
      .filter(item => item.value)

    const regionsData = await getRegionsQuery()
    let regionsOptions = regionsData?.data?.regions?.data
      .map(item => itemCreator(item, "region"))
      .filter(item => item.value)
    regionsOptions!.unshift({
      value: "All",
      label: "All",
      key: 0,
    })
    if (region?.id) {
      regionsOptions = regionsOptions?.filter(item => item.key == region.id)
    }

    setOptions({
      regions: regionsOptions!,
      distributors: distributorsOptions!,
    })

    setLoading(false)
  }

  const handleValuesChange = (value: string | object, type: string) => {
    const invitationKeys = ["distributor", "region"];

    if (invitationKeys.includes(type) && value) {
      setFilters({
        ...filters,
        ...invitationKeys
          .filter(key => key !== type)
          .reduce((acc, key) => {
            (acc as any)[key] = "";
            return acc;
          }, {}),
        [type]: value,
      })
      return
    }

    if (type == "date" || type == "time" || type == "timeZone") {
      setFilters({
        ...filters,
        dateAndTime: {
          ...(typeof filters?.dateAndTime == "object" ? { ...filters.dateAndTime } : {}),
          [type]: value,
        },
      })
      return
    }
    if (type == "youtubeLink") {
      setFilters({
        ...filters,
        image: null,
        [type]: value,
      })
      return
    }
    setFilters({
      ...filters,
      [type]: value,
    })
  }

  const handleFinish = async () => {
    const { title, description, distributor, region, youtubeLink, category, dateAndTime, image } = filters as any
    const convertObjectToDate = (dateAndTime: { date: string; time: string; timeZone: string }): Date => {
      const { date, time, timeZone } = dateAndTime
      const dateTimeString = `${date}T${time}:00.000`
      const dateTime: DateTime = DateTime.fromISO(dateTimeString, { zone: timeZone })
      return dateTime.toJSDate()
    }
    const convertTimeZone = (timeZone: string) =>
      timeZone.replace("+", "plus").replace("-", "minus") as EnumAppnewspostTimezone

    try {
      setFinishLoading(true)
      let imageId

      if (image) {
        if (post?.attributes?.image?.data?.id) {
          try {
            await fetch(url + `/api/upload/files/${post?.attributes?.image?.data?.id}`, {
              method: "DELETE",
              body: JSON.stringify({
                accessToken: localStorage.getItem("accessToken"),
              })
            })
          } catch (error) {
            console.log(error)
          }
        }

        const uploadResponse = await fetch(url + "/api/upload", {
          method: "POST",
          body: image,
        })
        const uploadData = await uploadResponse.json()
        if (!uploadData?.[0]?.id) {
          throw new Error("failed to upload image")
        }
        imageId = uploadData?.[0]?.id
      }
      const selectedRegion = options?.regions?.find(option => option.label == region)?.key
      const regionOption =
        +selectedRegion! > 0 ?
          [selectedRegion] :
          region == "All" ?
            options?.regions?.filter(option => option.key)?.map(option => option.key) :
            null

      const data: AppNewsPostInput = {
        author: userId,
        title: title,
        body: description,
        tag: category.trim(),
        type: "news",
        image: youtubeLink ? null : imageId,
        timeZone: convertTimeZone(dateAndTime.timeZone) || null,
        regions: regionOption,
        distributors: distributor ? [options?.distributors?.find(option => option.label == distributor)?.key] : null,
        link: youtubeLink || null,
        start_at:
          dateAndTime && areAllKeysPopulated(dateAndTime as object, []) ? convertObjectToDate(dateAndTime) : null,
      }

      let newPost
      let updatedPost: any
      let newTranslation
      if (!selectedPostId) {
        newPost = await createPost({
          variables: {
            data,
            locale: 'en'
          },
        })
        if (translationsVariants.length !== 3 && isAutoTranslateEnabled) {
          const { data: translation } = await translate({
            variables: {
              input: {
                langs: [Languages.FR, Languages.DE, Languages.ES],
                text: description,
                originalId: newPost?.data?.createAppNewsPost?.data?.id,
                onlyTranslationMode: false,
                collection: "api::app-news-post.app-news-post",
                localizedField: 'body'
              }
            }
          })
          newTranslation = translation?.translate?.translations
        } else {
          const { data: translation } = await translate({
            variables: {
              input: {
                langs: [Languages.FR, Languages.DE, Languages.ES],
                text: description,
                originalId: newPost?.data?.createAppNewsPost?.data?.id,
                onlyTranslationMode: false,
                collection: "api::app-news-post.app-news-post",
                variants: translationsVariants,
                localizedField: 'body'
              }
            }
          })
          newTranslation = translation?.translate?.translations
        }
      } else {

        updatedPost = await updatePost({
          variables: {
            id: String(selectedPostId),
            data,
            locale: 'en'
          },
        })
        if (!isAutoTranslateEnabled && translationsVariants.every(e => e.id)) {
          await deleteTranslations({
            variables: {
              input:
              {
                collection: "api::app-news-post.app-news-post",
                localizationsIds: translationsVariants.map(e => e.id),
                originalId: updatedPost.data?.updateAppNewsPost?.data?.id,
              }
            }
          })
        } else if (translationsVariants.length !== 3 && isAutoTranslateEnabled) {
          const { data: translation } = await translate({
            variables: {
              input: {
                langs: [Languages.FR, Languages.DE, Languages.ES],
                text: description,
                originalId: updatedPost.data?.updateAppNewsPost?.data?.id,
                onlyTranslationMode: false,
                collection: "api::app-news-post.app-news-post",
                localizedField: 'body'
              }
            }
          })
          newTranslation = translation?.translate?.translations
        } else {
          if (translationsVariants.every(e => e.id)) {
            await Promise.all(
              translationsVariants.map((variant) => {
                if (variant.id) {
                  updatePost({
                    variables: {
                      id: variant.id,
                      data: {
                        body: variant.text,
                      },
                      locale: variant.targetLanguage
                    },
                  });
                }
              })
            );
          } else {
            await translate({
              variables: {
                input: {
                  langs: [Languages.FR, Languages.DE, Languages.ES],
                  text: description,
                  originalId: updatedPost?.data?.updateAppNewsPost?.data?.id,
                  onlyTranslationMode: false,
                  collection: "api::app-news-post.app-news-post",
                  localizedField: 'body'
                }
              }
            })
          }
        }
      }

      if (newPost?.data?.createAppNewsPost?.data?.id || updatedPost?.data?.updateAppNewsPost?.data?.id) {
        let newFilters = JSON.parse(JSON.stringify(filters))
        newFilters.currentImage =
          newPost?.data?.createAppNewsPost?.data?.attributes?.image?.data?.attributes?.url ||
          updatedPost?.data?.updateAppNewsPost?.data?.attributes?.image?.data?.attributes?.url ||
          ""
        newFilters.image = null
        newTranslation ? setTranslationVariants(formatTranslates(newTranslation)) : setTranslationVariants(formatTranslates(translationsVariants))
        newTranslation ? setIntitalVariants(formatTranslates(newTranslation)) : setIntitalVariants(formatTranslates(translationsVariants))
        setSelectedDescriptionLanguage({ title: Languages.EN_FULL_TITLE, value: Languages.EN })
        setInitialFilters(newFilters)
        setFilters(newFilters)
      }

      setFinishLoading(false)
    } catch (error: any) {
      setFinishLoading(false)
      error!.message && setErrorMessage(truncate(error.message, 100))
    }
  }

  const createSelectElement = (
    type: string,
    name: string,
    loading: boolean,
    options: SelectItems,
    isRequired = true,
    isDisabled = false
  ) => {
    const value = isDisabled
      ? "ENG"
      : filters?.[type] || (filters?.dateAndTime as { [key: string]: string })?.[type] || ""
    const canBeRemoved = !isDisabled && !isRequired && value
    let SuffixIcon
    switch (type) {
      case "date":
        SuffixIcon = CalendarIcon
        break
      case "time":
        SuffixIcon = ClockIcon
        break
      default:
        SuffixIcon = SearchIcon
        break
    }

    const handleCalendarClick = (type: string) => {
      switch (type) {
        case "ok":
          calendarSelectElement?.current?.focus()
          calendarSelectElement?.current?.blur()
          break
        case "cancel":
          handleValuesChange("", "date")
          calendarSelectElement?.current?.focus()
          calendarSelectElement?.current?.blur()
          break
      }
    }

    return (
      <AntForm.Item name={name} label={name} required={isRequired}>
        <div className={"input-wrapper"}>
          {canBeRemoved && (
            <div className={"remove-icon"} children={<CloseIcon />} onClick={() => handleValuesChange("", type)} />
          )}
          <Select
            {...(type == "date" ? { ref: calendarSelectElement } : {})}
            showSearch
            disabled={isDisabled}
            value={value}
            optionFilterProp='children'
            onChange={data => handleValuesChange(data, type)}
            filterOption={filteredOption as any}
            options={options}
            defaultActiveFirstOption={true}
            notFoundContent={
              type == "date" ? (
                <div>
                  <CustomCalendar
                    fullscreen={false}
                    onSelect={(data: any) =>
                      handleValuesChange(
                        `${String(data.$y)}-${String(data.$M + 1).padStart(2, "0")}-${String(data.$D).padStart(
                          2,
                          "0"
                        )}`,
                        type
                      )
                    }
                  />
                  <div className={"calendar-buttons"}>
                    <button onClick={() => handleCalendarClick("cancel")} className={"cancel"} children={"Cancel"} />
                    <button onClick={() => handleCalendarClick("ok")} children={"OK"} />
                  </div>
                </div>
              ) : loading || !options ? (
                <div className={"no-data"} children={<Spin />} />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className={"no-data"} />
              )
            }
            suffixIcon={<SuffixIcon style={canBeRemoved ? { opacity: 0 } : {}} />}
          />
        </div>
      </AntForm.Item>
    )
  }

  const createInputElement = (type: string, label: string, isRequired = true) => (
    <AntForm.Item label={label} name='name' required={isRequired}>
      <div className={"input-wrapper"}>
        <Input
          placeholder={"Type here"}
          onChange={e => handleValuesChange(e.target.value, type)}
          value={(filters?.[type] as string) || ""}
        />
      </div>
    </AntForm.Item>
  )

  useEffect(() => {
    if (filters && initialFilters) {
      const notRequiredKeys = ["region", "distributor", "youtubeLink", "dateAndTime", "currentImage"]
      if (filters.currentImage || filters.youtubeLink) notRequiredKeys.push("image")

      const dateFilter = filters.dateAndTime as any
      const dateInitialFilter = initialFilters.dateAndTime as any
      const isDifferentRootValues = !areObjectsEqual(filters, initialFilters, "dateAndTime")
      const isDifferentDate = !areObjectsEqual(dateFilter, dateInitialFilter)
      const isTranslationsChanged = !deepEqualArrays(intitalVariants || [], translationsVariants || [])
      const isDateEmpty = !dateFilter.date && !dateFilter.time && !dateFilter.timeZone
      const isRootValuesValid = areAllKeysPopulated(filters, notRequiredKeys) && !!(filters.region || filters.distributor)
      const isDateValuesValid = isDateEmpty || areAllKeysPopulated(dateFilter, [])
      checkFormNotDone({ isDifferentRootValues })
      setIsFormReady((isDifferentRootValues || isDifferentDate || isTranslationsChanged) && isDateValuesValid && isRootValuesValid)
      errorMessage && setErrorMessage("")
    }
  }, [filters, initialFilters, translationsVariants])

  useEffect(() => {
    getItems()
  }, [])


  return (
    <>
      <AntForm layout='vertical'>
        <div className={"info-wrapper post"}>
          <div className={"block"}>
            <div className={"title"}>TITLE</div>
            <div className={"inputs-wrapper wide-input"}>{createInputElement("title", "Title")}</div>
          </div>

          <div className={"block"}>
            <div className="block-header">
              <div className={"title"}>DESCRIPTION</div>
              <Dropdown className={isTranslationLoading || !isAutoTranslateEnabled ? "dropdown-disabled" : ''} disabled={isTranslationLoading || !isAutoTranslateEnabled} menu={{ items: generateItems({ setSelectedDescriptionLanguage }) }}>
                <Space>
                  {selectedDescriptionLanguage.title}
                  <DownOutlined />
                </Space>
              </Dropdown>
            </div>
            <div className={"inputs-wrapper wide-input"}>
              <TransatableEditableContentWrapper
                isAutoTranslateEnabled={isAutoTranslateEnabled}
                selectedLanguage={selectedDescriptionLanguage}
                toggleSwitcher={toggleSwitcher}
                renderApplyButton={true}
                translate={handleTranslation}
                text={(filters?.description as string)}
                isTranslationLoading={isTranslationLoading}>
                <div className={"inputs-wrapper wide-input"}>
                  <TextEditor
                    englishVersion={(filters?.description as string)}
                    handleMarkdown={handleValuesChange}
                    title={"Description"}
                    type={"description"}
                    language={selectedDescriptionLanguage.value}
                    fetchedMarkdown={post?.attributes?.description as string | undefined}
                    setTranslationVariants={setTranslationVariants}
                    translationsVariants={translationsVariants}
                  />
                </div>
              </TransatableEditableContentWrapper>
            </div>
          </div>

          <div className={"block"}>
            <div className={"title"}>LINKS</div>
            <div className={"inputs-wrapper wide-input"}>
              {createInputElement("youtubeLink", "YouTube Link", false)}
            </div>
          </div>

          <div className={"block"}>
            <div className={"title"}>OTHER</div>
            <div className={"inputs-wrapper"}>
              {createInputElement("category", "Category")}
              {createSelectElement("", "Language", false, [], false, true)}
            </div>
          </div>

          <div className={"block"}>
            <div className={"title invitation"}>
              <span>* </span>
              <div>INVITATION</div>
            </div>
            <div className={"extra-description"}>
              Choose a region or a specific users who will be able to see the post.
            </div>
            <div className={"inputs-wrapper"}>
              {createSelectElement("region", "Region", loading, options.regions!, false, false)}
              {createSelectElement("distributor", "Distributor", loading, options.distributors!, false, false)}
            </div>
          </div>

          <div className={"block"}>
            <div className={"title"}>
              <div children={"SCHEDULE"} />
              <div children={"TIME ZONE"} />
            </div>
            <div className={"inputs-wrapper"}>
              {createSelectElement("date", "Launch date", loading, [], false, false)}
              {createSelectElement("timeZone", "Time zone", loading, generateTimeZoneOptions(), false, false)}
              {createSelectElement("time", "Launch time", loading, generateTimeOptions(), false, false)}
            </div>
          </div>

          {errorMessage && <div className={"error"}>{errorMessage}</div>}
        </div>
      </AntForm>

      {blockPermissions?.edit && (
        <button
          type={"submit"}
          onClick={handleFinish}
          className={`save ${!(!isFormReady || isChangesDisabled) && "ready"}`}
          disabled={!isFormReady || isChangesDisabled || finishLoading}
        >
          Save
          {finishLoading ? <Spin /> : <SaveIcon />}
        </button>
      )}

      {selectedPostId && blockPermissions?.delete ? (
        <button onClick={() => showModal()} className={"delete"}>
          Delete
          <TrashIcon />
        </button>
      ) : null}
      {
        createPortal(<TranslationModal
          open={isTranslationModalOpen}
          onOk={() => setAutoTranslateEnabled(false)}
          onCancel={() => setAutoTranslateEnabled(true)}
          setOpen={setIsTranslationModalOpen} />, document.body)
      }
    </>

  )
}

export default Form
